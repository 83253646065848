import Vue from 'vue'

import i18n from './plugins/i18n'
import './plugins/axios'
import './plugins/meta'
import './plugins/element'
import './plugins/svgicon'
import './plugins/timeago'

import router from './router'
import store from './store'

import App from './App.vue'
import './assets/styles/global.scss'
import './registerServiceWorker'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
