/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M12 1v6a3 3 0 000 6v9.31a1 1 0 01-.7-.29l-5.66-5.66A9 9 0 0112 1z"/><path pid="1" class="primary" d="M12 1a9 9 0 016.36 15.36l-5.65 5.66a1 1 0 01-.71.3V13a3 3 0 000-6V1z"/>'
  }
})
