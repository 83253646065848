/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M6.59 16H3a1 1 0 01-1-1V9a1 1 0 011-1h3.59l4.7-4.7A1 1 0 0113 4v16a1 1 0 01-1.7.7L6.58 16z"/><path pid="1" class="secondary" d="M14.83 9.17a1 1 0 111.41-1.41 6 6 0 010 8.48 1 1 0 01-1.41-1.41 4 4 0 000-5.66z"/>'
  }
})
