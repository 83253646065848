/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M9 21a1 1 0 01-.45-.1l-6-3A1 1 0 012 17V4a1 1 0 011.45-.9L9 5.89V21zm6-18a1 1 0 01.45.1l6 3A1 1 0 0122 7v13a1 1 0 01-1.45.9L15 18.12V3z"/><path pid="1" class="primary" d="M9 21V5.88l5.55-2.77A1 1 0 0115 3v15.1l-5.55 2.8A1 1 0 019 21z"/>'
  }
})
