/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" fill-rule="evenodd" d="M17 11a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4H7a1 1 0 010-2h4V7a1 1 0 012 0v4h4z"/>'
  }
})
