import Vue from 'vue'
import axios from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import { getArrayIdsAndObject, arrayToObject } from '@/utils'

const sourceTypes = {
  SmartPixel: 'pixel',
  HybridDspDataSource: 'dsp',
}
const sourceStatuses = ['pending', 'active', 'working', 'stalled', 'deactivated']

const timeOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

// test data
const workspaces = [
  {
    name: 'User activity',
    description: 'Shows user website activity analysis',
    id: '1',
    items: [
      {
        type: 'profiles',
        group: 'data',
        filters: [],
        sorts: [],
        attributes: [
          {
            type: 'user',
            visible: true,
            list: [
              { id: '1234', visible: true },
              { id: '1235', visible: true },
              { id: '1236', visible: true },
              { id: '1237', visible: true },
              { id: '1238', visible: false },
              { id: '1239', visible: true },
            ],
          },
          {
            type: 'computed',
            visible: true,
            list: [{ id: '1231', visible: true }],
          },
        ],
      },
      {
        type: 'segments',
        group: 'data',
        filters: [],
        sorts: [],
        ids: ['1', '2', '3'],
      },
      {
        type: 'exports',
        group: 'data',
        filters: [],
        sorts: [],
        ids: ['1', '2', '3'],
      },
    ],
    pinned: true,
    visited: 1569830150104,
  },
  {
    name: "Business KPI's",
    description: 'Goal pergormance',
    id: '2',
    items: [],
    pinned: true,
    visited: 1569830150104,
  },
  {
    name: 'Cart page effectivity',
    description: 'Should we use cart page?',
    id: '3',
    items: [
      {
        type: 'profiles',
        group: 'data',
        filters: [],
        sorts: [],
        attributes: [
          {
            type: 'user',
            visible: true,
            list: [
              { id: '1234', visible: true },
              { id: '1235', visible: false },
              { id: '1236', visible: true },
              { id: '1237', visible: true },
              { id: '1238', visible: true },
              { id: '1239', visible: true },
            ],
          },
          {
            type: 'computed',
            visible: false,
            list: [{ id: '1231', visible: false }],
          },
        ],
      },
    ],
    visited: 1569830150104,
  },
]
const profiles = [
  {
    id: '31414',
    user: [
      { name: 'Name', value: 'Peter Gibbons', id: 'name' },
      { name: 'Email', value: 'peter@initech.com', id: '1234' },
      { name: 'Age', value: 35, id: '1235' },
      { name: 'Gender', value: 'male', id: '1236' },
      { name: 'Plan', value: 'premium', id: '1237' },
      { name: 'Logins', value: 5, id: '1238' },
      { name: 'Time Spent', value: 0.4, id: '1239' },
    ],
    computed: [{ name: 'Average time spent last 30 days', value: 5.3, id: '1231' }],
  },
  {
    id: '3141444',
    user: [
      { name: 'Name', value: 'Sarah Peterson', id: 'name' },
      { name: 'Email', value: 'sarah@peterson.com', id: '1234' },
      { name: 'Age', value: 42, id: '1235' },
      { name: 'Gender', value: 'female', id: '1236' },
      { name: 'Plan', value: 'free', id: '1237' },
      { name: 'Logins', value: 4, id: '1238' },
      { name: 'Time Spent', value: 0.2, id: '1239' },
    ],
    computed: [{ name: 'Average time spent last 30 days', value: 2.4, id: '1231' }],
  },
  {
    id: '3141466',
    user: [
      { name: 'Name', value: 'Jack White', id: 'name' },
      { name: 'Email', value: 'jack@white.com', id: '1234' },
      { name: 'Age', value: 2323, id: '1235' },
      { name: 'Gender', value: 'male', id: '1236' },
      { name: 'Plan', value: 'basic', id: '1237' },
      { name: 'Logins', value: 12, id: '1238' },
      { name: 'Time Spent', value: 7.2, id: '1239' },
    ],
    computed: [{ name: 'Average time spent last 30 days', value: 10.4, id: '1231' }],
  },
]
const events = [
  {
    id: '1',
    name: 'Sign up',
    attributes: ['name', '1234', '1235'],
    group: 'Web activity',
    description: 'User signed up to a platform',
  },
  {
    id: '2',
    name: 'Add to cart',
    attributes: ['name', '1234', '1235', '1236'],
    group: 'Web activity',
    description: 'User added item to cart',
  },
  {
    id: '3',
    name: 'Place an order',
    attributes: ['1237', '1234', '1235'],
    group: 'Web activity',
    description: 'User placed order in market',
  },
  {
    id: '4',
    name: 'Log out',
    attributes: ['name', '1234', '1239'],
    group: 'Web activity',
    description: 'User logs out of platform',
  },
  {
    id: '5',
    name: 'Subscribe to push notifications',
    attributes: ['name', '1234', '1235'],
    group: 'Notifications',
    description: 'User agreed to receive push notifications',
  },
  {
    id: '6',
    name: 'Unsubscribe from push notifications',
    attributes: ['name', '1234', '1235'],
    group: 'Notifications',
    description: 'User refused to receive push notifications',
  },
  {
    id: '7',
    name: 'Follow push notifications',
    attributes: ['name', '1237', '1235'],
    group: 'Notifications',
  },
]
const attributes = [
  {
    id: 'name',
    name: 'Name',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1234',
    name: 'Email',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1235',
    name: 'Age',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1236',
    name: 'Gender',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1237',
    name: 'Plan',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1238',
    name: 'Logins',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1239',
    name: 'Time Spent',
    type: 'user',
    profiles: 123,
    description: 'Description text',
  },
  {
    id: '1231',
    name: 'Average time spent last 30 days',
    type: 'computed',
    profiles: 12,
    description: 'Description text',
  },
]
const attributesHistory = {
  name: [{ value: 'Peter Gibbons', dt: 1569830150104 }, { value: 'Jeff2', dt: 1569830150105 }],
  '1234': [
    { value: 'Jeff1@email.com', dt: 1569830150105 },
    { value: 'Jeff2@email.com', dt: 1569830150106 },
    { value: 'Jeff3@email.com', dt: 1569830150107 },
  ],
  '1235': [{ value: 34, dt: 1569830150104 }],
  '1236': [{ value: 'male', dt: 1569830150104 }],
  '1237': [{ value: 'basic', dt: 1569830150104 }],
  '1238': [{ value: 22, dt: 1569830150104 }, { value: 44, dt: 1569830150105 }],
  '1239': [
    { value: 34535, dt: 1569830150104 },
    { value: 53435, dt: 1569830150105 },
    { value: 2342, dt: 1569830150106 },
  ],
  '1231': [
    { value: 34535, dt: 1569830150104 },
    { value: 53435, dt: 1569830150105 },
    { value: 2342, dt: 1569830150106 },
  ],
}
const segments = {
  '1': {
    id: '1',
    name: 'Awesome segment',
    profiles: 345,
    description: 'All segments are extraordinary.',
    updated: 1569830150104,
    status: 'active',
  },
  '2': {
    id: '2',
    name: 'Cool segment',
    profiles: 345,
    description: 'All segments are extraordinary.',
    updated: 1569830150104,
    status: 'pending',
  },
  '3': {
    id: '3',
    name: 'Delightful segment',
    profiles: 345,
    description: 'All segments are extraordinary.',
    updated: 1569830150104,
    status: 'active',
  },
  '4': {
    id: '4',
    name: 'Best segment',
    profiles: 345,
    description: 'All segments are extraordinary.',
    created: 1569830150104,
    updated: 1569830150104,
    status: 'active',
  },
  '5': {
    id: '5',
    name: 'Marvelous segment',
    profiles: 345,
    description: 'All segments are extraordinary.',
    created: 1569830150104,
    updated: 1569830150104,
    status: 'pending',
  },
}
const exports = {
  '1': {
    id: '1',
    name: 'Awesome export 1',
    type: 'periodic',
    segmentIds: ['1', '2', '3', '4'],
    exportTo: 'Some target 1',
    updated: 1569830150104,
    status: 'active',
  },
  '2': {
    id: '2',
    name: 'Awesome export 2',
    type: 'once',
    segmentIds: ['1'],
    exportTo: 'Some target 2',
    updated: 1569830150004,
    status: 'completed',
  },
  '3': {
    id: '3',
    name: 'Awesome export 3',
    type: 'periodic',
    segmentIds: ['1', '2', '3'],
    exportTo: 'Some target 3',
    updated: 1569830150155,
    status: 'aborted',
  },
}

export default {
  namespaced: true,

  state: {
    loading: false,

    clouds: null,
    activeCloudId: '',
    activeWorkspaceId: '',

    cloudSources: {},
    cloudWorkspaces: {},
    cloudProfiles: {},
    profileAttributesHistory: {},
    cloudEvents: {},
    cloudAttributes: {},
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setActiveCloudId(state, id) {
      state.activeCloudId = id
    },
    setActiveWorkspaceId(state, id) {
      state.activeWorkspaceId = id
    },
    setClouds(state, clouds) {
      clouds.forEach(cloud => {
        cloud.sources = []
        cloud.profiles = []
        cloud.events = []
        cloud.attributes = []
        cloud.workspaces = []
      })
      state.clouds = arrayToObject(clouds)
    },
    setCloudSources(state, { id, sources }) {
      const [ids, object] = getArrayIdsAndObject(sources)
      Vue.set(state.clouds[id], 'sources', ids)
      Vue.set(state.cloudSources, id, object)
    },
    setCloudWorkspaces(state, { id, workspaces }) {
      workspaces.forEach(workspace => {
        workspace.items &&
          workspace.items.forEach(item => {
            workspace[item.type] = item
          })
      })
      const [ids, object] = getArrayIdsAndObject(workspaces)
      Vue.set(state.clouds[id], 'workspaces', ids)
      Vue.set(state.cloudWorkspaces, id, object)
    },
    setCloudProfiles(state, { id, profiles }) {
      const [ids, object] = getArrayIdsAndObject(profiles)
      Vue.set(state.clouds[id], 'profiles', ids)
      Vue.set(state.cloudProfiles, id, object)
    },
    setCloudEvents(state, { id, events }) {
      const [ids, object] = getArrayIdsAndObject(events)
      Vue.set(state.clouds[id], 'events', ids)
      Vue.set(state.cloudEvents, id, object)
    },
    setCloudAttributes(state, { id, attributes }) {
      const [ids, object] = getArrayIdsAndObject(attributes)
      Vue.set(state.clouds[id], 'attributes', ids)
      Vue.set(state.cloudAttributes, id, object)
    },
    setProfileAttributesHistory(state, { profileId, attributeId, history }) {
      if (!state.profileAttributesHistory[profileId]) {
        Vue.set(state.profileAttributesHistory, profileId, {})
      }
      Vue.set(state.profileAttributesHistory[profileId], attributeId, history)
    },
  },

  getters: {
    activeCloud: state => state.clouds && state.clouds[state.activeCloudId],

    activeSources: state => state.cloudSources[state.activeCloudId],
    activeWorkspaces: state => state.cloudWorkspaces[state.activeCloudId],
    activeProfiles: state => state.cloudProfiles[state.activeCloudId],
    activeEvents: state => state.cloudEvents[state.activeCloudId],
    activeAttributes: state => state.cloudAttributes[state.activeCloudId],

    activeSourcesList: (state, getters) =>
      getters.activeCloud ? getters.activeCloud.sources.map(id => getters.activeSources[id]) : [],
    activeWorkspacesList: (state, getters) =>
      getters.activeCloud
        ? getters.activeCloud.workspaces.map(id => getters.activeWorkspaces[id])
        : [],
    activeProfilesList: (state, getters) =>
      getters.activeCloud ? getters.activeCloud.profiles.map(id => getters.activeProfiles[id]) : [],
    activeEventsList: (state, getters) =>
      getters.activeCloud ? getters.activeCloud.events.map(id => getters.activeEvents[id]) : [],
    activeAttributesList: (state, getters) =>
      getters.activeCloud
        ? getters.activeCloud.attributes.map(id => getters.activeAttributes[id])
        : [],
    activeAttributeGroups: (state, getters) =>
      getters.activeAttributesList.reduce((groups, attribute) => {
        if (!groups[attribute.type]) {
          groups[attribute.type] = []
        }
        groups[attribute.type].push(attribute.id)
        return groups
      }, {}),

    activeWorkspace: (state, getters) =>
      getters.activeWorkspaces && state.activeWorkspaceId
        ? getters.activeWorkspaces[state.activeWorkspaceId]
        : {},
    activeWorkspaceAttributes: (state, getters) =>
      getters.activeWorkspace.profiles && getters.activeWorkspace.profiles.attributes,
    activeWorkspaceAttributesList: (state, getters) =>
      getters.activeWorkspaceAttributes &&
      getters.activeWorkspaceAttributes
        .reduce((attributes, group) => {
          if (group.visible) {
            attributes.push(...group.list.filter(item => item.visible).map(item => item.id))
          }
          return attributes
        }, [])
        .map(id => getters.activeAttributes[id]),
    activeWorkspaceSegments: (state, getters) =>
      getters.activeWorkspace.segments &&
      getters.activeWorkspace.segments.ids.map(id => {
        const item = segments[id]
        item.updated = new Date(item.updated).toLocaleTimeString(i18n.locale, timeOptions)
        return item
      }),
    activeWorkspaceExports: (state, getters) =>
      getters.activeWorkspace.exports &&
      getters.activeWorkspace.exports.ids.map(id => {
        const item = exports[id]
        if (item.segmentIds) {
          item.segmentNames = item.segmentIds.map(id => segments[id].name).join(', ')
        }
        item.updated = new Date(item.updated).toLocaleTimeString(i18n.locale, timeOptions)
        return item
      }),
  },

  actions: {
    async getData({ getters, dispatch, commit }, view) {
      // add promise.all
      commit('setLoading', true)
      !getters.activeWorkspacesList.length && (await dispatch('getCloudWorkspaces'))

      switch (view) {
        case 'dashboard':
          !getters.activeSourcesList.length && (await dispatch('getCloudSources'))
          !getters.activeProfilesList.length && (await dispatch('getCloudProfiles'))
          break
        case 'data':
          !getters.activeSourcesList.length && (await dispatch('getCloudSources'))
          !getters.activeAttributesList.length && (await dispatch('getCloudAttributes'))
          !getters.activeEventsList.length && (await dispatch('getCloudEvents'))
          break
        case 'workspace':
          !getters.activeProfilesList.length && (await dispatch('getCloudProfiles'))
          !getters.activeAttributesList.length && (await dispatch('getCloudAttributes'))
          !getters.activeEventsList.length && (await dispatch('getCloudEvents'))
          break
        case 'workspaces':
          !getters.activeProfilesList.length && (await dispatch('getCloudProfiles'))
          !getters.activeAttributesList.length && (await dispatch('getCloudAttributes'))
          break
      }
      commit('setLoading', false)
    },
    async getClouds({ state, commit }) {
      try {
        // Replace the axios call with mock data
        const clouds = [
          { id: 1, name: 'Cloud 1', visited: Date.now(), visitedLabel: '4:30 PM' },
          { id: 2, name: 'Cloud 2', visited: Date.now(), visitedLabel: '5:00 PM' },
          { id: 3, name: 'Cloud 3', visited: Date.now(), visitedLabel: '6:00 PM' },
        ]
        clouds.forEach(cloud => {
          if (cloud.visited) {
            cloud.visitedLabel = new Date(cloud.visited).toLocaleTimeString(
              i18n.locale,
              timeOptions
            )
          }
        })
        commit('setClouds', clouds)
        if (!state.activeCloudId) {
          commit('setActiveCloudId', clouds[0].id)
        }
        return {}
      } catch (error) {
        return { error }
      }
    }
    ,
    async getCloudSources({ commit }) {
      try {
        // Replace the axios call with mock data
        const sources = [
          { id: 1, name: 'Source 1' },
          { id: 2, name: 'Source 2' },
          { id: 3, name: 'Source 3' },
        ]
        const clouds = sources.reduce((clouds, source) => {
          if (!clouds[source.dataCloudId]) {
            clouds[source.dataCloudId] = []
          }
          source.type = sourceTypes[source.$type]
          source.status = sourceStatuses[source.status - 1]
          clouds[source.dataCloudId].push(source)
          return clouds
        }, {})
        Object.keys(clouds).forEach(id => {
          // const id = cloudId || state.activeCloudId
          commit('setCloudSources', { id, sources: clouds[id] })
        })
        return {}
      } catch (error) {
        return { error }
      }
    },
    async getCloudWorkspaces({ commit, state }, cloudId) {
      try {
        // todo: get sources by cloud from api
        // const workspaces = await axios.get('/api/workspaces')
        const recievedWorkspaces = JSON.parse(JSON.stringify(workspaces))
        recievedWorkspaces.forEach(workspace => {
          workspace.visitedLabel = new Date(workspace.visited).toLocaleTimeString(
            i18n.locale,
            timeOptions
          )
        })
        const id = cloudId || state.activeCloudId
        commit('setCloudWorkspaces', { id, workspaces: recievedWorkspaces })
        return {}
      } catch (error) {
        return { error }
      }
    },
    async getCloudProfiles({ commit, state }, cloudId) {
      const id = cloudId || state.activeCloudId
      commit('setCloudProfiles', { id, profiles })
    },
    async getCloudEvents({ commit, state }, cloudId) {
      const id = cloudId || state.activeCloudId
      commit('setCloudEvents', { id, events })
    },
    async getCloudAttributes({ commit, state }, cloudId) {
      const id = cloudId || state.activeCloudId
      commit('setCloudAttributes', { id, attributes })
    },
    async getProfileAttributeHistory({ commit }, { profileId, attributeId }) {
      const history = attributesHistory[attributeId] // get by api
      history.forEach(item => {
        item.dtLabel = new Date(item.dt).toLocaleTimeString(i18n.locale, timeOptions)
      })
      commit('setProfileAttributesHistory', {
        profileId,
        attributeId,
        history,
      })
    },
    async changeProfileAttribute({ dispatch }, { profileId, attributeId, value }) {
      attributesHistory[attributeId].unshift({ value, dt: Date.now() })
      const profileIndex = profiles.findIndex(profile => profile.id === profileId)
      const attributeIndex = profiles[profileIndex].user.findIndex(
        attribute => attribute.id === attributeId
      )
      profiles[profileIndex].user[attributeIndex].value = value
      await dispatch('getCloudProfiles')
      await dispatch('getProfileAttributeHistory', { profileId, attributeId })
      return {}
    },

    async createCloud({ dispatch }, { name, description }) {
      try {
        await axios.post('/api/datacloud', { name, description })
        await dispatch('getClouds')
        return {}
      } catch (error) {
        return { error }
      }
    },
    async createCloudWorkspace({ dispatch, getters }, { type, name, description }) {
      try {
        // await axios.post('/api/workspace', { name, description })
        const workspace = {
          name,
          description,
          items: [],
          id: `${workspaces.length + 1}`,
          visited: Date.now(),
        }
        if (type === 'default') {
          workspace.items = [
            {
              group: 'data',
              type: 'profiles',
              filters: [],
              sorts: [],
              attributes: Object.keys(getters.activeAttributeGroups).map(group => ({
                type: group,
                visible: true,
                list: getters.activeAttributeGroups[group].map(id => ({
                  id,
                  visible: true,
                })),
              })),
            },
            {
              type: 'segments',
              group: 'data',
              filters: [],
              sorts: [],
              ids: [],
            },
            { type: 'exports', group: 'data', filters: [], sorts: [], ids: [] },
          ]
        }
        workspaces.unshift(workspace)
        await dispatch('getCloudWorkspaces')
        return { id: workspace.id }
      } catch (error) {
        return { error }
      }
    },
    async duplicateCloudWorkspace({ dispatch }, workspace) {
      try {
        // await axios.post('/api/workspace', { name, description })
        workspace.id = `${workspaces.length + 1}`
        workspace.visited = Date.now()
        workspaces.unshift(workspace)
        await dispatch('getCloudWorkspaces')
        return { id: workspace.id }
      } catch (error) {
        return { error }
      }
    },
    async editCloud({ dispatch }, { id, name, description }) {
      try {
        await axios.put('/api/datacloud', { id, name, description })
        await dispatch('getClouds')
        return {}
      } catch (error) {
        return { error }
      }
    },
    async editCloudWorkspace({ dispatch }, { id, data }) {
      try {
        const index = workspaces.findIndex(workspace => workspace.id === id)
        workspaces[index] = { ...workspaces[index], ...data }
        // await axios.put('/api/workspace', { id, ...data })
        await dispatch('getCloudWorkspaces')
        return {}
      } catch (error) {
        return { error }
      }
    },
    async editCloudAttributes({ dispatch }, updatedAttributes) {
      updatedAttributes.forEach(updatedAttribute => {
        const index = attributes.findIndex(attribute => attribute.id === updatedAttribute.id)
        Object.keys(updatedAttribute).forEach(key => {
          if (key !== 'id') {
            attributes[index][key] = updatedAttribute[key]
          }
        })
      })
      await dispatch('getCloudAttributes')

      return {}
    },
    async editWorkspaceAttributes({ dispatch, state }, updatedAttributes) {
      try {
        const index = workspaces.findIndex(workspace => workspace.id === state.activeWorkspaceId)
        const profilesIndex = workspaces[index].items.findIndex(item => item.type === 'profiles')
        workspaces[index].items[profilesIndex].attributes = [...updatedAttributes]
        await dispatch('getCloudWorkspaces')
        return {}
      } catch (error) {
        return { error }
      }
    },
    async addCloudWorkspaceItem({ dispatch, state }, item) {
      try {
        const index = workspaces.findIndex(workspace => workspace.id === state.activeWorkspaceId)
        workspaces[index].items.push(item)
        // await axios.put('/api/workspace', { id, ...data })
        await dispatch('getCloudWorkspaces')
        return {}
      } catch (error) {
        return { error }
      }
    },
  },
}
