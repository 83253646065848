import axios from '@/plugins/axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    isChecked: false,

    createAccountId: '',

    resetTokenExpired: false,
    passwordResetSuccess: false,
  },
  mutations: {
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
      state.isChecked = true
    },
    setChecked(state) {
      state.isChecked = true
    },

    setCreateAccountId(state, url) {
      state.createAccountId = url
    },

    setResetTokenExpired(state, isExpired) {
      state.resetTokenExpired = isExpired
    },
    setPasswordResetSuccess(state, isSuccess) {
      state.passwordResetSuccess = isSuccess
    },
  },
  actions: {
    async login({ dispatch, commit }, { email, password }) {
      try {
        // Mock successful login
        const mockLoginSuccess = true;
        if (email && password)
          alert('Mock successful login')
        if (mockLoginSuccess) {
          commit('setAuthenticated', true)
          const { error } = await dispatch('user/checkUser', undefined, { root: true })
          if (error) {
            return { error }
          }
          return {}
        } else {
          // If you need to use real API login, you can comment out the block below
          /*
          const { status, accountInfoUrl } = await axios.post('/api/login', {
            email,
            password,
          })
          if (status === 1) {
            const id = accountInfoUrl.split('api/login/account/')[1]
            commit('setCreateAccountId', id)
            return { createAccount: true }
          }

          commit('setAuthenticated', true)
          const { error } = await dispatch('user/checkUser', undefined, { root: true })
          if (error) {
            return { error }
          }
          */
        }
        return {}
      } catch (error) {
        return { error }
      }
    },

    async createAccount({ dispatch, state, commit }, { company, phone }) {
      try {
        await axios.post(`/api/login/account/${state.createAccountId}`, {
          company,
          phone,
        })
        commit('setAuthenticated', true)
        const { error } = await dispatch('user/checkUser', undefined, { root: true })
        if (error) {
          return { error }
        }
        return {}
      } catch (error) {
        return { error }
      }
    },

    async logout({ commit }) {
      try {
        await axios.get('/api/logout')
        commit('setAuthenticated', false)
        await router.push('/auth')
        commit('reset', undefined, { root: true })
        return {}
      } catch (error) {
        return { error }
      }
    },

    async signup(context, { name, email, company, password, phone }) {
      try {
        await axios.post('/api/registration', {
          name,
          email,
          company,
          password,
          phone,
        })
        return {}
      } catch (error) {
        return { error }
      }
    },

    async forgotPassword(context, { email }) {
      try {
        await axios.put('/api/password/reset', { email })
        return {}
      } catch (error) {
        return { error }
      }
    },

    async resetPassword({ commit }, { token, password }) {
      try {
        await axios.post(`/api/password/reset/${token}`, { password })
        commit('setPasswordResetSuccess', true)
        router.push('/auth')
      } catch (error) {
        if (error.status === 400) {
          commit('setResetTokenExpired', true)
          router.push('/auth')
        }
      }
    },

    async checkResetToken({ commit }, token) {
      try {
        const expired = await axios.get(`/api/password/reset/${token}`)
        if (expired) {
          commit('setResetTokenExpired', true)
          router.push('/auth')
        }
      } catch (error) {
        router.push('/auth')
      }
    },
  },
}
