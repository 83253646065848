/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buoy': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M2.43 14.92A2 2 0 011 13v-2a2 2 0 011.43-1.92 10.02 10.02 0 016.65-6.65A2 2 0 0111 1h2a2 2 0 011.92 1.43c3.17.97 5.68 3.48 6.65 6.65A2 2 0 0123 11v2a2 2 0 01-1.43 1.92 10.02 10.02 0 01-6.65 6.65A2 2 0 0113 23h-2a2 2 0 01-1.92-1.43 10.02 10.02 0 01-6.65-6.65zM12 17l1.79-1.42a4.02 4.02 0 001.8-1.8L17 12l-1.42-1.79a4.02 4.02 0 00-1.8-1.79L12 7l-1.79 1.42a4.02 4.02 0 00-1.79 1.8L7 12l1.42 1.78a4.02 4.02 0 001.8 1.8L12 17z"/><path pid="1" class="primary" d="M12 2c1.6 0 3.13.38 4.47 1.05L13.8 8.42a3.98 3.98 0 00-3.58 0L7.53 3.05A9.96 9.96 0 0112 2zM2 12c0-1.6.38-3.13 1.05-4.47l5.37 2.68a3.98 3.98 0 000 3.58l-5.37 2.68A9.96 9.96 0 012 12zm8.21 3.58a3.98 3.98 0 003.58 0l2.68 5.37a9.96 9.96 0 01-8.94 0l2.68-5.37zm10.74-8.05a9.96 9.96 0 010 8.94l-5.37-2.68a3.98 3.98 0 000-3.58l5.37-2.68z"/>'
  }
})
