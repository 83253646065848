/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M16.85 6.06A3.52 3.52 0 0121 9.5a3.5 3.5 0 01-6.96.5H14a3 3 0 112.85-3.94z"/><path pid="1" class="primary" d="M5.03 12.12A5.5 5.5 0 0116 11.26 4.5 4.5 0 1117.5 20H6a4 4 0 01-.97-7.88z"/>'
  }
})
