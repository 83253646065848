/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M13 9.41V17a1 1 0 01-2 0V9.41l-2.3 2.3a1 1 0 11-1.4-1.42l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.42L13 9.4z"/>'
  }
})
