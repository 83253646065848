/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fast-forward': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M1 5a1 1 0 011.5-.86l12 7a1 1 0 010 1.72l-12 7A1 1 0 011 19V5z"/><path pid="1" class="primary" d="M9 5a1 1 0 011.5-.86l12 7a1 1 0 010 1.72l-12 7A1 1 0 019 19V5z"/>'
  }
})
