/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-thick-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M14 12v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5H8a1 1 0 01-.7-1.7l4-4a1 1 0 011.4 0l4 4A1 1 0 0116 12h-2z"/>'
  }
})
