/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M22 15v4a1 1 0 01-1 1H9V4h12a1 1 0 011 1v4a3 3 0 000 6z"/><path pid="1" class="primary" d="M9 20H3a1 1 0 01-1-1v-4a3 3 0 000-6V5a1 1 0 011-1h6v16z"/>'
  }
})
