/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M13 11.59l3.2 3.2a1 1 0 01-1.4 1.42l-3.5-3.5A1 1 0 0111 12V7a1 1 0 012 0v4.59z"/>'
  }
})
