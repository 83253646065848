/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M22 10H2V6c0-1.1.9-2 2-2h7l2 2h7a2 2 0 012 2v2z"/><rect pid="1" width="20" height="12" x="2" y="8" class="secondary" rx="2"/><path pid="2" class="primary" d="M13 13h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2H9a1 1 0 010-2h2v-2a1 1 0 012 0v2z"/>'
  }
})
