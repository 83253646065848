/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'box': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M5 5h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V7c0-1.1.9-2 2-2zm4 5a1 1 0 000 2h6a1 1 0 000-2H9z"/><path pid="1" class="primary" d="M4 3h16a2 2 0 012 2v1a1 1 0 01-1 1H3a1 1 0 01-1-1V5c0-1.1.9-2 2-2z"/>'
  }
})
