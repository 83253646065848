/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M12 12a5 5 0 110-10 5 5 0 010 10z"/><path pid="1" class="secondary" d="M21 20v-1a5 5 0 00-5-5H8a5 5 0 00-5 5v1c0 1.1.9 2 2 2h14a2 2 0 002-2z"/>'
  }
})
