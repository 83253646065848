/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brick': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path id="primary" fill-rule="evenodd" clip-rule="evenodd" d="M3 6.61529C3 6.32583 3.29794 6.13221 3.56246 6.24977L12 9.99979V21.9998L3.23755 18.1054C3.09309 18.0412 3 17.8979 3 17.7398V6.61529Z" fill="#ACB3DA"/><path id="secondary" fill-rule="evenodd" clip-rule="evenodd" d="M21 6.61551C21 6.32604 20.7021 6.13242 20.4375 6.24998L12 10V22L20.7625 18.1056C20.9069 18.0414 21 17.8981 21 17.7401V6.61551Z" fill="#1F263C"/><path id="Vector 1" d="M11.8759 2.55643L7.10084 4.72689C6.86636 4.83347 6.86636 5.16653 7.10084 5.27311L11.8759 7.44357C11.9547 7.47943 12.0453 7.47943 12.1241 7.44357L16.8992 5.27311C17.1336 5.16653 17.1336 4.83347 16.8992 4.72689L12.1241 2.55643C12.0453 2.52057 11.9547 2.52057 11.8759 2.55643Z" fill="#4652AD"/>'
  }
})
