/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document-remove': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M6 2h6v6c0 1.1.9 2 2 2h6v10a2 2 0 01-2 2H6a2 2 0 01-2-2V4c0-1.1.9-2 2-2z"/><rect pid="1" width="8" height="2" x="8" y="15" class="secondary" rx="1"/><path pid="2" class="secondary" d="M14 2l6 6h-6z"/>'
  }
})
