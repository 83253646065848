/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'security-important': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M4 4l8-2 8 2v9.06a8 8 0 01-4.42 7.15L12 22l-3.58-1.79A8 8 0 014 13.06V4z"/><path pid="1" class="secondary" d="M12 18a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 011-1.1h1a1 1 0 011 1.1l-.5 5z"/>'
  }
})
