/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 012 2v15a1 1 0 01-1.7.7L16.58 17H4a2 2 0 01-2-2z"/><path pid="1" class="secondary" d="M6 7h12a1 1 0 010 2H6a1 1 0 110-2zm0 4h8a1 1 0 010 2H6a1 1 0 010-2z"/>'
  }
})
