/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" fill-rule="evenodd" d="M17 11a1 1 0 010 2H7a1 1 0 010-2h10z"/>'
  }
})
