/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'server': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 3h14a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2zm0 10h14a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4c0-1.1.9-2 2-2zm2 3a1 1 0 000 2h3a1 1 0 000-2H7z"/><rect pid="1" width="5" height="2" x="6" y="6" class="secondary" rx="1"/>'
  }
})
