/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-thick-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M12 10h5a1 1 0 011 1v2a1 1 0 01-1 1h-5v2a1 1 0 01-1.7.7l-4-4a1 1 0 010-1.4l4-4A1 1 0 0112 8v2z"/>'
  }
})
