<template>
  <el-container class="app-main-menu-container">
    <el-header class="app-main-menu" height="50px">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col class="app-main-menu__title">
          <h6>{{ title }}</h6>
        </el-col>
        <el-col v-if="submenuItems[mainPath]" class="app-main-menu__submenu">
          <el-button
            v-for="name in submenuItems[mainPath]"
            :key="name"
            size="small"
            :type="$route.name === name ? 'primary' : 'default'"
            :disabled="name === 'settingsCloud' && !$store.getters['data/activeCloud']"
            plain
            @click="$router.push({ name })"
          >
            {{ $t(`pages.${name}`) }}
          </el-button>
        </el-col>
        <el-col class="app-main-menu__user">
          <el-popover
            v-model="showSearch"
            placement="top-end"
            trigger="click"
            popper-class="app-main-menu__search-popper"
            :visible-arrow="false"
          >
            <el-button
              slot="reference"
              :class="[
                'app-main-menu__btn',
                'app-main-menu__apps-btn',
                { 'app-main-menu__btn--active': showSearch },
              ]"
              type="text"
              size="small"
            >
              <icon name="search" height="20" width="20" />
            </el-button>
            <div>
              <el-input
                ref="search"
                v-model="searchQuery"
                :placeholder="$t('actions.search')"
                size="small"
                class="app-main-menu__search-input"
              />
              <div v-if="!searchQuery.length" class="app-main-menu__search-content">
                <icon class="app-main-menu__search-icon" name="box" width="32" height="32" />
                <span>{{ $t('enterText') }}</span>
              </div>
              <div v-else-if="searchResults.length">
                <div v-for="result in searchResults" :key="result.label">
                  <span class="app-main-menu__search-title">{{ result.label }}</span>
                  <el-divider></el-divider>
                  <ul class="app-main-menu__search-list">
                    <li
                      v-for="(option, index) in result.options"
                      :key="option.label + index"
                      :class="[
                        'app-main-menu__search-item',
                        {
                          'app-main-menu__search-item--active': $route.path === option.path,
                        },
                      ]"
                      @click="handleSearchOptionClick(option.path)"
                    >
                      {{ option.label }}
                      <p v-if="option.description">{{ option.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="app-main-menu__search-content">
                <icon class="app-main-menu__search-icon" name="empty" width="32" height="32" />
                <p>{{ $t('noDataFound') }}</p>
              </div>
            </div>
          </el-popover>
          <el-popover
            v-model="showNotification"
            placement="top-end"
            trigger="click"
            :visible-arrow="false"
            @after-leave="notifications.length && $store.commit('clearNotifications')"
          >
            <el-button
              slot="reference"
              :class="[
                'app-main-menu__btn',
                'app-main-menu__apps-btn',
                { 'app-main-menu__btn--active': showNotification },
              ]"
              type="text"
              size="small"
            >
              <el-badge is-dot :hidden="!notifications.length">
                <icon
                  class="app-main-menu__btn app-main-menu__notification-btn"
                  name="notification"
                  width="20"
                  height="20"
                />
              </el-badge>
            </el-button>
            <div v-if="notifications.length">
              <div
                v-for="notification in notifications"
                :key="notification.time"
                class="notification"
              >
                <icon :name="notification.icon || 'error-circle'"></icon>
                <div class="notification__content">
                  <span>{{ notification.message }}</span>
                  <span class="notification__content__time">{{
                    $timeago.format(notification.time, $i18n.locale)
                  }}</span>
                </div>
              </div>
            </div>
            <div v-else class="app-main-menu__empty-notification">
              <icon name="empty" width="32" height="32" />
              <span>{{ $t('noNotifications') }}</span>
            </div>
          </el-popover>

          <el-popover v-model="showApps" placement="top-end" trigger="click" :visible-arrow="false">
            <el-button
              slot="reference"
              :class="[
                'app-main-menu__btn',
                'app-main-menu__apps-btn',
                { 'app-main-menu__btn--active': showApps },
              ]"
              type="text"
              size="small"
            >
              <icon
                class="app-main-menu__btn app-main-menu__apps-btn"
                name="apps"
                width="20"
                height="20"
              />
            </el-button>
            <a
              v-for="app in hybridApps"
              :key="app.label"
              class="apps__item"
              :href="app.link"
              target="_blank"
            >
              <span :style="{ 'background-color': app.color }" class="apps__item__icon">
                <icon :name="app.icon" color="white" width="20" height="20" />
              </span>
              <span class="apps__item__title">{{ app.label }}</span>
            </a>
            <el-divider />
            <span class="apps__item__more">{{ $t('moreAboutHSuite') }}</span>
            <a key="Hybrid" class="apps__item" href="https://hybrid.ai/suite" target="_blank">
              <span class="apps__item__icon apps__item__icon--hybrid"> </span>
              <span class="apps__item__title">Hybrid.ai</span>
            </a>
          </el-popover>

          <el-popover
            v-model="showUserMenu"
            placement="top-end"
            trigger="click"
            :visible-arrow="false"
            class="app-main-menu__user"
          >
            <el-avatar
              v-if="user && user.avatar"
              slot="reference"
              size="small"
              :src="user.avatar"
            />
            <el-avatar v-else slot="reference" size="small">{{ user.name.slice(0, 1) }}</el-avatar>

            <div class="user-menu__user-content">
              <b>{{ user.name }}</b>
              <span>{{ user.email }}</span>
            </div>
            <router-link
              :class="[
                'app-main-menu__user-item',
                {
                  'app-main-menu__user-item--active': $route.name === 'settingsProfile',
                },
              ]"
              to="/settings/profile"
              @click.native="showUserMenu = false"
            >
              {{ $t('pages.settingsProfile') }}
            </router-link>
            <router-link
              :class="[
                'app-main-menu__user-item',
                {
                  'app-main-menu__user-item--active': $route.name === 'settingsWallet',
                },
              ]"
              to="/settings/wallet"
              @click.native="showUserMenu = false"
            >
              {{ $t('pages.settingsWallet') }}
            </router-link>
            <el-divider />
            <router-link
              class="app-main-menu__user-item"
              to="/clouds"
              @click.native="showUserMenu = false"
            >
              {{ $t('changeCloud') }}
            </router-link>
            <div class="app-main-menu__user-item" @click="handleLogout">
              {{ $t('logout') }}
            </div>
          </el-popover>
        </el-col>
      </el-row>
    </el-header>
  </el-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppMainMenu',

  data() {
    return {
      showUserMenu: false,
      showSearch: false,
      showNotification: false,
      showApps: false,
      searchQuery: '',

      hybridApps: [
        {
          label: 'Insights',
          link: 'https://console.hybrid.ru/',
          color: '#F76C18',
          icon: 'dashboard',
        },
        {
          label: 'Console',
          link: 'https://insights.hybrid.ru/',
          color: '#685AE4',
          icon: 'cog',
        },
        {
          label: 'Reports',
          link: 'https://reports.hybrid.ru/',
          color: '#2FAF74',
          icon: 'trending-up',
        },
      ],
      submenuItems: {
        data: ['dataSources', 'dataAttributes', 'dataMarketplace'],
        settings: ['settingsProfile', 'settingsWallet', 'settingsCloud'],
      },
    }
  },

  computed: {
    ...mapState({
      notifications: state => state.notifications,
      user: state => state.user.user,
    }),
    ...mapGetters('data', ['activeWorkspace', 'activeWorkspacesList']),
    mainPath() {
      return this.$route.path.split('/')[1]
    },
    title() {
      if (this.$route.name === 'workspace') {
        return this.activeWorkspace.name
      } else {
        return this.$i18n.t(`pages.${this.mainPath}`)
      }
    },
    searchResults() {
      const results = []
      const query = this.searchQuery.toLowerCase()
      const workspaces = this.activeWorkspacesList.filter(workspace =>
        ['name', 'description'].some(field =>
          workspace[field]
            .toString()
            .toLowerCase()
            .includes(query)
        )
      )
      if (workspaces.length) {
        results.push({
          label: this.$i18n.t('pages.workspaces'),
          icon: 'inbox-full',
          options: workspaces.map(workspace => ({
            label: workspace.name,
            description: workspace.description,
            path: `/workspace/${workspace.id}`,
          })),
        })
      }
      return results
    },
  },

  watch: {
    showSearch(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search && this.$refs.search.focus()
        })
      }
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    async handleLogout() {
      await this.logout()
      this.showUserMenu = false
    },
    handleSearchOptionClick(path) {
      if (this.$router.path !== path) {
        this.$router.push(path)
      }
      this.showSearch = false
      this.searchQuery = ''
    },
  },
}
</script>

<i18n>
{
  "en": {
    "settings": "Settings",
    "moreAboutHSuite": "More about Hybrid Suite",
    "noNotifications": "No new notifications",
    "notifications": "Notifications",
    "changeCloud": "Change cloud",
    "logout": "Logout",
    "enterText": "Enter text for cloud search"
  },
  "ru": {
    "settings": "Настройки",
    "moreAboutHSuite": "Подробнее о Hybrid Suite",
    "noNotifications": "Нет новых уведомлений",
    "notifications": "Уведомления",
    "changeCloud": "Сменить облако",
    "logout": "Выйти",
    "enterText": "Введите текст для поиска в облаке"
  }
}
</i18n>

<style lang="scss">
.app-main-menu-container {
  max-height: 50px;
}
.app-main-menu {
  background: $color-layout-light-01;
  width: 100%;
}
.app-main-menu__submenu {
  display: flex;
  justify-content: center;
}
.app-main-menu__btn {
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
}
.app-main-menu__empty-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-main-menu__user {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notification {
  display: flex;
  max-width: 340px;
  margin-bottom: $spacing-s;
}
.notification__content {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.notification__content__time {
  font-size: 12px;
  opacity: 0.8;
}
.apps__item {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-m;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.apps__item__icon {
  margin-right: $spacing-s;
  width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #685ae4;
  > span {
    font-size: 16px;
    color: white;
  }
}
.apps__item__title {
  font-weight: 500;
  color: #1e254e;
}
.apps__item__more {
  margin-bottom: 8px;
  display: block;
  font-size: 12px;
}
.user-menu__user-content {
  padding: 12px 20px 12px 20px;
  background: $color-layout-light-01;
  margin: -13px -13px 0px -13px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid rgba(67, 90, 111, 0.1);
  & > * {
    display: block;
  }

  & > *:first-child {
    margin-bottom: $spacing-2xs;
    font-weight: 600;
  }
}
.apps__item__icon--hybrid {
  position: relative;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    top: calc(50% - 16px / 2);
    left: calc(50% - 12px / 2);
    background: url(~@/assets/images/hybrid_logo.svg) no-repeat;
  }
}
.app-main-menu__user {
  padding-left: calc(4px * 2);
  .el-avatar {
    cursor: pointer;
  }
}
.app-main-menu__user-item {
  padding: $spacing-xs;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  color: $color-layout-dark-02;
  &:hover {
    color: $color-primary;
  }
  &:last-child {
    color: $color-danger;
  }
  & ~ .el-divider {
    margin: $spacing-2xs 0;
  }
}
.app-main-menu__btn--active {
  background: $color-layout-light-02;
}
.app-main-menu__btn.el-button.el-button--small {
  height: auto;
}
.app-main-menu__search-input {
  margin-bottom: $spacing-s;
}
.app-main-menu__search-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.app-main-menu__search-icon {
  margin-bottom: $spacing-xs;
  text-align: center;
  & ~ span {
    display: block;
    max-width: 80%;
  }
}
.app-main-menu__search-popper {
  width: 230px;
}
.app-main-menu__search-title {
  margin-bottom: $spacing-2xs;
  font-weight: 500;
  display: block;
}
.app-main-menu__search-list {
  margin-bottom: $spacing-xs;
  li {
    position: relative;
    list-style: none;
    margin-top: $spacing-s;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      color: $color-primary;
    }
    & > *:first-child {
      font-weight: 400;
    }
  }
}
.app-main-menu__user-item--active {
  color: $color-primary;
}
.app-main-menu__search-item--active {
  color: $color-primary;
}
</style>
