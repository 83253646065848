/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-ascending': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M18 13v7a1 1 0 01-2 0v-7h-3a1 1 0 01-.7-1.7l4-4a1 1 0 011.4 0l4 4A1 1 0 0121 13h-3z"/><path pid="1" class="primary" d="M3 3h13a1 1 0 010 2H3a1 1 0 110-2zm0 4h9a1 1 0 010 2H3a1 1 0 110-2zm0 4h5a1 1 0 010 2H3a1 1 0 010-2z"/>'
  }
})
