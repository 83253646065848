export const arrayToObject = arr =>
  arr.reduce((obj, item) => {
    obj[item.id] = item
    return obj
  }, {})

export const arrayToIds = arr => arr.map(item => item.id)

export const getArrayIdsAndObject = arr => {
  const ids = []
  const obj = arr.reduce((obj, item) => {
    ids.push(item.id)
    obj[item.id] = item
    return obj
  }, {})
  return [ids, obj]
}
