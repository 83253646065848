/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'translate': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M10.41 10l1.3 1.3a1 1 0 01-1.42 1.4L9 11.42l-3.3 3.3a1 1 0 11-1.4-1.42L7.58 10l-1.3-1.3A1 1 0 017.7 7.3L9 8.58l.54-.54A5 5 0 0010.98 5H3a1 1 0 110-2h5V2a1 1 0 112 0v1h5a1 1 0 010 2h-2.02a7 7 0 01-2.03 4.46l-.54.54z"/><path pid="1" class="secondary" d="M13.33 18l-1.4 3.38a1 1 0 01-1.85-.76l5-12a1 1 0 011.84 0l5 12a1 1 0 01-1.84.76L18.67 18h-5.34zm.84-2h3.66L16 11.6 14.17 16z"/>'
  }
})
