/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hour-glass': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M19 20h1a1 1 0 010 2H4a1 1 0 010-2h1c0-1.8.68-3.58 2.05-4.95L9 13.1v-2.2L7.05 8.95A6.98 6.98 0 015 4H4a1 1 0 110-2h16a1 1 0 010 2h-1c0 1.8-.68 3.58-2.05 4.95L15 10.9v2.2l1.95 1.95A6.98 6.98 0 0119 20z"/><path pid="1" class="secondary" d="M17 20H7l2.83-2.83A4 4 0 0011 14.34v-4.27L8.46 7.54a5 5 0 01-.95-1.33c.17-.06.33-.13.49-.21a4.47 4.47 0 014 0c1.26.63 2.74.63 4 0 .23-.11.46-.2.7-.28a5 5 0 01-1.16 1.82L13 10.07v4.27a4 4 0 001.17 2.83L17 20z"/>'
  }
})
