/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apps': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 13h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4c0-1.1.9-2 2-2zm10 0h4a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4c0-1.1.9-2 2-2zM5 3h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2z"/><path pid="1" class="secondary" d="M18 6h2a1 1 0 010 2h-2v2a1 1 0 01-2 0V8h-2a1 1 0 010-2h2V4a1 1 0 012 0v2z"/>'
  }
})
