import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
  actions: {
    async checkUser({ commit }) {
      try {
        const user = {
          id: 1,
          name: 'John Doe',
          email: 'john.doe@example.com',
        }
        commit('setUser', user)
        commit('auth/setAuthenticated', true, { root: true })
        return {}
      } catch (error) {
        commit('auth/setAuthenticated', false, { root: true })
        return { error }
      }
    },

    async editUser({ commit, state }, data) {
      try {
        await axios.put('/api/user', data)
        commit('setUser', { ...state.user, ...data })
        return {}
      } catch (error) {
        return { error }
      }
    },

    async sendEmailConfirmation() {
      try {
        await axios.post('/api/email/confirm')
        return {}
      } catch (error) {
        return { error }
      }
    },
  },
}
