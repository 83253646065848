/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M19.48 13.03l-.02-.03a1 1 0 111.75-.98A6 6 0 0116 21h-4a6 6 0 110-12h1a1 1 0 010 2h-1a4 4 0 100 8h4a4 4 0 003.48-5.97z"/><path pid="1" class="primary" d="M4.52 10.97l.02.03a1 1 0 11-1.75.98A6 6 0 018 3h4a6 6 0 110 12h-1a1 1 0 010-2h1a4 4 0 100-8H8a4 4 0 00-3.48 5.97z"/>'
  }
})
