/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'code': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="18" height="18" x="3" y="3" class="primary" rx="2"/><path pid="1" class="secondary" d="M8.7 13.3a1 1 0 01-1.4 1.4l-2-2a1 1 0 010-1.4l2-2a1 1 0 111.4 1.4L7.42 12l1.3 1.3zm6.6 0l1.29-1.3-1.3-1.3a1 1 0 111.42-1.4l2 2a1 1 0 010 1.4l-2 2a1 1 0 01-1.42-1.4zm-3.32 3.9a1 1 0 01-1.96-.4l2-10a1 1 0 011.96.4l-2 10z"/>'
  }
})
