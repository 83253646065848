/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-group': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M12 13a3 3 0 013-3h4a3 3 0 013 3v3a1 1 0 01-1 1h-8a1 1 0 01-1-1 1 1 0 01-1 1H3a1 1 0 01-1-1v-3a3 3 0 013-3h4a3 3 0 013 3zM7 9a3 3 0 110-6 3 3 0 010 6zm10 0a3 3 0 110-6 3 3 0 010 6z"/><path pid="1" class="secondary" d="M12 13a3 3 0 110-6 3 3 0 010 6zm-3 1h6a3 3 0 013 3v3a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a3 3 0 013-3z"/>'
  }
})
