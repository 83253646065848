/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-closed': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M5 3h2l5 2 5-2h2a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2z"/><path pid="1" class="primary" d="M7 3h10v11a1 1 0 01-1.45.9L12 13.11l-3.55 1.77A1 1 0 017 14V3z"/>'
  }
})
