/* eslint-disable */
require('./account')
require('./add-circle')
require('./add-square')
require('./add')
require('./airplane')
require('./analytics')
require('./announcement')
require('./application')
require('./apps')
require('./arrow-down')
require('./arrow-left')
require('./arrow-right')
require('./arrow-thick-down')
require('./arrow-thick-left')
require('./arrow-thick-right')
require('./arrow-thick-up')
require('./arrow-up')
require('./arrows-merge')
require('./arrows-split')
require('./article')
require('./asterisk')
require('./at')
require('./attach')
require('./battery-full')
require('./battery-half')
require('./bolt')
require('./book-closed')
require('./book-open')
require('./box')
require('./brick')
require('./bug')
require('./buoy')
require('./business')
require('./calculator')
require('./calendar-add')
require('./calendar-date')
require('./calendar-remove')
require('./camera')
require('./certificate')
require('./chat-group-alt')
require('./chat')
require('./check-circle')
require('./cheveron-down-circle')
require('./cheveron-down')
require('./cheveron-left-circle')
require('./cheveron-right-circle')
require('./cheveron-selection')
require('./cheveron-up-circle')
require('./cheveron-up')
require('./click-target')
require('./clock')
require('./close-square')
require('./close')
require('./cloud')
require('./code')
require('./cog')
require('./collection')
require('./column')
require('./compass')
require('./copy')
require('./credit-card')
require('./currency-dollar')
require('./currency-euro')
require('./dashboard')
require('./date')
require('./deliver')
require('./desktop')
require('./device-smartphone')
require('./device-tablet')
require('./dialog')
require('./discount')
require('./document-add')
require('./document-remove')
require('./document')
require('./door-enter')
require('./door-exit')
require('./dots-horizontal')
require('./dots-vertical')
require('./download')
require('./drag-grid')
require('./drag-row')
require('./edit')
require('./empty')
require('./error-circle')
require('./export')
require('./external-window')
require('./factory')
require('./fast-forward')
require('./fast-rewind')
require('./film')
require('./filter')
require('./flag')
require('./folder-add')
require('./folder-remove')
require('./folder')
require('./globe')
require('./hard-drive')
require('./headphones')
require('./heart')
require('./help-circle')
require('./help')
require('./history')
require('./home')
require('./hour-glass')
require('./identification')
require('./import')
require('./inbox-check')
require('./inbox-full')
require('./interface')
require('./key')
require('./launch')
require('./library')
require('./light')
require('./link')
require('./location-pin')
require('./lock-open')
require('./lock')
require('./mail')
require('./map')
require('./menu')
require('./microphone')
require('./money')
require('./monitor')
require('./mood-happy')
require('./mood-neutral')
require('./mood-sad')
require('./news')
require('./notification-off')
require('./notification')
require('./office')
require('./order-horizontal')
require('./order-vertical')
require('./package')
require('./paint')
require('./pause')
require('./phone-incoming-call')
require('./phone-outgoing-call')
require('./phone-ring')
require('./photo')
require('./pie-chart')
require('./pin')
require('./play')
require('./presentation-play')
require('./presentation')
require('./print')
require('./puzzle')
require('./receipt')
require('./refresh')
require('./remove-circle')
require('./remove-square')
require('./remove')
require('./scale')
require('./search')
require('./security-check')
require('./security-important')
require('./security')
require('./send')
require('./server')
require('./shopping-bag')
require('./shopping-basket')
require('./shopping-cart')
require('./sign')
require('./sort-ascending')
require('./sort-decending')
require('./star')
require('./stop')
require('./store')
require('./survey')
require('./swatch')
require('./tag')
require('./target')
require('./text-cursor')
require('./thermostat-full')
require('./thermostat-half')
require('./thumbs-down')
require('./thumbs-up')
require('./ticket')
require('./translate')
require('./trash')
require('./trending-down')
require('./trending-up')
require('./trophy')
require('./umbrella')
require('./uploadsvg')
require('./user-add')
require('./user-check')
require('./user-couple')
require('./user-group')
require('./user-remove')
require('./user')
require('./videocam')
require('./view-hidden')
require('./view-visible')
require('./volume-down')
require('./volume-mute')
require('./volume-up')
require('./wallet')
require('./warning-circle')
require('./wifi-off')
require('./wifi')
require('./zoom-in')
require('./zoom-out')
