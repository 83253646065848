/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="14" height="14" x="3" y="3" class="secondary" rx="2"/><rect pid="1" width="14" height="14" x="7" y="7" class="primary" rx="2"/>'
  }
})
