/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M5 8h14a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5c0-1.1.9-2 2-2zm1 2a1 1 0 000 2h2a1 1 0 000-2H6z"/><path pid="1" class="primary" d="M6 14h12v6a1 1 0 01-1 1H7a1 1 0 01-1-1v-6zm0-6V4a1 1 0 011-1h10a1 1 0 011 1v4H6z"/>'
  }
})
