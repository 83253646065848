/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'videocam': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M13.59 12l6.7-6.7A1 1 0 0122 6v12a1 1 0 01-1.7.7L13.58 12z"/><rect pid="1" width="14" height="14" x="2" y="5" class="primary" rx="2"/>'
  }
})
