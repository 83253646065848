/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumbs-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M11 19.2l-2.92-6.8A1 1 0 018 12V4c0-1.1.9-2 2-2h6c1.5 0 3.11 1.06 3.7 2.45l2.22 5.16A1 1 0 0122 10v2a3 3 0 01-3 3h-4v4a3 3 0 01-3 3 1 1 0 01-1-1v-1.8z"/><rect pid="1" width="4" height="11" x="2" y="2" class="secondary" rx="1" transform="rotate(180 4 7.5)"/>'
  }
})
