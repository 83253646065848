/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove-square': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="18" height="18" x="3" y="3" class="primary" rx="2"/><rect pid="1" width="12" height="2" x="6" y="11" class="secondary" rx="1"/>'
  }
})
