/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mood-sad': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M16.47 18H7.53a1 1 0 01-.75-1.67 6.98 6.98 0 0110.44 0 1 1 0 01-.75 1.67zM8.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm7 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"/>'
  }
})
