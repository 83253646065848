/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M9 8h1a1 1 0 011 1v6a1 1 0 01-1 1H9a1 1 0 01-1-1V9a1 1 0 011-1zm5 0h1a1 1 0 011 1v6a1 1 0 01-1 1h-1a1 1 0 01-1-1V9a1 1 0 011-1z"/>'
  }
})
