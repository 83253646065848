/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M3.66 17.52a10 10 0 1116.68 0C19.48 16.02 17.86 16 16 16H8c-1.86 0-3.48.01-4.34 1.52z"/><path pid="1" class="primary" d="M3.66 17.52A5 5 0 018 15h8a5 5 0 014.34 2.52 10 10 0 01-16.68 0zM12 13a4 4 0 110-8 4 4 0 010 8z"/>'
  }
})
