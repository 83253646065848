/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scale': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M11 6.39L6.32 7.95a1 1 0 01-.64 0l-3-1a1 1 0 01.64-1.9l2.68.9 5-1.67V3a1 1 0 012 0v1.28l5 1.67 2.68-.9a1 1 0 01.64 1.9l-3 1a1 1 0 01-.64 0L13 6.39V18c0 1.1.9 2 2 2h1v2H8v-2h1a2 2 0 002-2V6.39z"/><path pid="1" class="secondary" d="M17.05 6.68a1 1 0 011.9 0l3 9a1 1 0 01-.4 1.15A6.36 6.36 0 0118 18c-1.2 0-2.4-.4-3.55-1.17a1 1 0 01-.4-1.15l3-9zM19.61 15L18 10.16 16.39 15h3.22zM5.05 6.68a1 1 0 011.9 0l3 9a1 1 0 01-.4 1.15A6.36 6.36 0 016 18c-1.2 0-2.4-.4-3.55-1.17a1 1 0 01-.4-1.15l3-9zM7.61 15L6 10.16 4.39 15H7.6z"/>'
  }
})
