/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M12 22a10 10 0 110-20 10 10 0 010 20z"/><path pid="1" class="secondary" d="M12 19.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1-5.5a1 1 0 01-2 0v-1.41a1 1 0 01.55-.9L14 10.5c.64-.42 1-.97 1-1.5 0-1.03-1.3-2-3-2-1.35 0-2.49.62-2.87 1.43a1 1 0 01-1.8-.86C8.05 6.01 9.92 5 12 5c2.7 0 5 1.72 5 4 0 1.3-.76 2.46-2.05 3.24L13 13.2v.8z"/>'
  }
})
