/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swatch': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M9 22c.19-.14.37-.3.54-.46L17.07 14H20a2 2 0 012 2v4a2 2 0 01-2 2H9zM4 2h4a2 2 0 012 2v14a4 4 0 11-8 0V4c0-1.1.9-2 2-2zm2 17.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/><path pid="1" class="secondary" d="M11 18.66V7.34l2.07-2.07a2 2 0 012.83 0l2.83 2.83a2 2 0 010 2.83L11 18.66z"/>'
  }
})
