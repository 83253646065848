/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M4 15a1 1 0 01-1-1V4a1 1 0 011-1h7a1 1 0 01.7.3L13.42 5H21a1 1 0 01.9 1.45L19.61 11l2.27 4.55A1 1 0 0121 17h-8a1 1 0 01-.7-.3L10.58 15H4z"/><rect pid="1" width="2" height="20" x="2" y="2" class="primary" rx="1"/>'
  }
})
