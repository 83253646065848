/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'store': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 8h14a1 1 0 011 1v11a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1zm1 2v7h12v-7H6z"/><path pid="1" class="secondary" d="M6 12a4 4 0 01-4-4 1 1 0 01.1-.45l2-4A1 1 0 015 3h14a1 1 0 01.9.55l2 4c.06.14.1.3.1.45a4 4 0 01-7 2.65 3.99 3.99 0 01-6 0A3.99 3.99 0 016 12z"/>'
  }
})
