var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name !== 'clouds')?_c('el-aside',{staticClass:"layout-content__menu-container",attrs:{"width":"auto"}},[_c('el-menu',{staticClass:"layout-content__menu",attrs:{"default-active":`/${_vm.activeItem}`,"collapse":_vm.collapsed,"router":""}},[_c('el-menu-item',{staticClass:"layout-content__menu__item layout-content__logo"},[_c('icon',{attrs:{"width":"28","height":"28","name":"brick"}}),_c('span',[_c('b',[_vm._v("hybrid")]),_vm._v(" warehouse")])],1),_c('el-menu-item',{class:[
        'layout-content__menu__item',
        { 'layout-content__menu__item--active': _vm.activeItem === 'dashboard' },
      ],attrs:{"index":"/dashboard"}},[_c('icon',{attrs:{"width":"20","height":"20","name":"dashboard"}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages.dashboard')))])],1),_c('el-menu-item',{class:[
        'layout-content__menu__item',
        { 'layout-content__menu__item--active': _vm.activeItem === 'data' },
      ],attrs:{"index":"/data"}},[_c('icon',{attrs:{"width":"20","height":"20","name":"server"}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages.data')))])],1),_c('el-menu-item',{class:[
        'layout-content__menu__item',
        { 'layout-content__menu__item--active': _vm.activeItem === 'workspaces' },
      ],attrs:{"index":"/workspaces"}},[_c('icon',{attrs:{"width":"20","height":"20","name":"inbox-full"}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages.workspaces')))])],1),(!_vm.collapsed && _vm.activeWorkspacesList)?_c('div',{staticClass:"layout-content__menu__workspace"},[_c('el-menu-item-group',{attrs:{"title":_vm.$t('pinnedWorkspaces')}},_vm._l((_vm.pinnedWorkspaces),function(workspace){return _c('el-menu-item',{key:workspace.id,class:[
            'submenu__workspaces__item',
            {
              'layout-content__menu__item--active':
                _vm.activeItem === 'workspace' && _vm.$route.params.id === workspace.id,
            },
          ],attrs:{"index":`/workspace/${workspace.id}`}},[_vm._v(" "+_vm._s(workspace.name)+" ")])}),1)],1):_vm._e(),_c('div',{staticClass:"layout-content__placeholder"}),_c('el-menu-item',{class:[
        'layout-content__menu__item',
        { 'layout-content__menu__item--active': _vm.activeItem === 'settings' },
      ],attrs:{"index":"/settings"}},[_c('icon',{attrs:{"width":"20","height":"20","name":"cog"}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages.settings')))])],1),_c('el-menu-item',{staticClass:"layout-content__menu__item",on:{"click":_vm.toggleCollapsed}},[_c('icon',{attrs:{"width":"20","height":"20","name":_vm.collapsed ? 'arrow-right' : 'arrow-left'}}),_c('span',[_vm._v(_vm._s(_vm.$t('collapse')))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }