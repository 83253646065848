/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumbs-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M13 4.8l2.92 6.8a1 1 0 01.08.4v8a2 2 0 01-2 2H8a4.28 4.28 0 01-3.7-2.45L2.07 14.4A1 1 0 012 14v-2a3 3 0 013-3h4V5a3 3 0 013-3 1 1 0 011 1v1.8z"/><rect pid="1" width="4" height="11" x="18" y="11" class="secondary" rx="1"/>'
  }
})
