/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M12 1a4 4 0 014 4v6a4 4 0 11-8 0V5a4 4 0 014-4z"/><path pid="1" class="primary" d="M13 18.94V21h3a1 1 0 010 2H8a1 1 0 010-2h3v-2.06A8 8 0 014 11a1 1 0 012 0 6 6 0 1012 0 1 1 0 012 0 8 8 0 01-7 7.94z"/>'
  }
})
