/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M15.51 11.14a1 1 0 010 1.72l-5 3A1 1 0 019 15V9a1 1 0 011.51-.86l5 3z"/>'
  }
})
