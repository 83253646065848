/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'security': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M4 4l8-2 8 2v9.06a8 8 0 01-4.42 7.15L12 22l-3.58-1.79A8 8 0 014 13.06V4z"/><path pid="1" class="secondary" d="M6 12V5.56l6-1.5V12H6zm6 7.76V12h6v1.06a6 6 0 01-3.32 5.36L12 19.76z"/>'
  }
})
