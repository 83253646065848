/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'target': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M15.23 2.53l-.35.35a3 3 0 00-.8 1.4 8.01 8.01 0 105.64 5.63 3 3 0 001.4-.79l.35-.35A9.99 9.99 0 0112 22a10 10 0 113.23-19.47zM13.55 6.2L11.75 8a4 4 0 104.24 4.25l1.8-1.8a6 6 0 11-4.24-4.25z"/><path pid="1" class="secondary" d="M16 6.59V5a1 1 0 01.3-.7l2-2A1 1 0 0120 3v1h1a1 1 0 01.7 1.7l-2 2a1 1 0 01-.7.3h-1.59l-4.7 4.7a1 1 0 01-1.42-1.4L16 6.58z"/>'
  }
})
