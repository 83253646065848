/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M9 22H5a1 1 0 01-1-1V11l8-8 8 8v10a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 00-1-1h-2a1 1 0 00-1 1v4a1 1 0 01-1 1zm3-9a2 2 0 100-4 2 2 0 000 4z"/><path pid="1" class="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 11-1.42-1.41l9.02-9.02a1 1 0 011.41 0l8.99 9.02a1 1 0 01-1.42 1.41l-8.28-8.3z"/>'
  }
})
