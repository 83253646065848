/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'launch': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M14.57 6.96a2 2 0 012.47 2.47c.29.17.5.47.5.86v7.07a1 1 0 01-.3.71L13 22.31a1 1 0 01-1.7-.7v-3.58l-.49.19a1 1 0 01-1.17-.37 14.1 14.1 0 00-3.5-3.5 1 1 0 01-.36-1.16l.19-.48H2.39A1 1 0 011.7 11l4.24-4.24a1 1 0 01.7-.3h7.08c.39 0 .7.21.86.5zM13.19 9.4l-2.15 2.15a3 3 0 01.84.57 3 3 0 01.57.84l2.15-2.15a2 2 0 01-1.4-1.41zm6.98-6.61a1 1 0 011.04 1.04c-.03.86-.13 1.71-.3 2.55-.47-.6-1.99-.19-2.55-.74-.55-.56-.14-2.08-.74-2.55.84-.17 1.7-.27 2.55-.3z"/><path pid="1" class="secondary" d="M7.23 10.26A16.05 16.05 0 0117.62 3.1a19.2 19.2 0 013.29 3.29 15.94 15.94 0 01-7.17 10.4 19.05 19.05 0 00-6.51-6.52zm-.86 5.5a16.2 16.2 0 011.87 1.87 1 1 0 01-.47 1.6c-.79.25-1.6.42-2.4.54a1 1 0 01-1.14-1.13c.12-.82.3-1.62.53-2.41a1 1 0 011.6-.47zm7.34-5.47a2 2 0 102.83-2.83 2 2 0 00-2.83 2.83z"/>'
  }
})
