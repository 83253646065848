/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'currency-dollar': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M13 7.12v3.96c2.25.36 4 1.93 4 3.92 0 2-1.75 3.56-4 3.92V19a1 1 0 01-2 0v-.08a4.82 4.82 0 01-3.67-2.49 1 1 0 011.8-.85c.29.6.98 1.09 1.87 1.3v-3.96C8.75 12.56 7 11 7 9s1.75-3.56 4-3.92V5a1 1 0 012 0v.08a4.81 4.81 0 013.68 2.5 1 1 0 01-1.81.85c-.28-.6-.98-1.1-1.87-1.31zm-2 3.76V7.12C9.81 7.4 9 8.18 9 9c0 .82.81 1.6 2 1.88zm2 2.24v3.76c1.19-.28 2-1.06 2-1.88 0-.82-.81-1.6-2-1.88z"/>'
  }
})
