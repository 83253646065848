/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'door-enter': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M13 4V3a1 1 0 011.27-.96l6.98 2A1 1 0 0122 5v14a1 1 0 01-.75.97l-6.98 2A1 1 0 0113 21v-1h-3a1 1 0 01-1-1v-2a1 1 0 012 0v1h2V6h-2v1a1 1 0 01-2 0V5a1 1 0 011-1h3z"/><path pid="1" class="secondary" d="M7.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 010 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4L7.6 13H3a1 1 0 010-2h4.59z"/>'
  }
})
