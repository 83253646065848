/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'presentation-play': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M11 18.62l-6.55 3.27a1 1 0 01-.9-1.78L11 16.38V5a1 1 0 012 0v11.38l7.45 3.73a1 1 0 01-.9 1.78L13 18.62V21a1 1 0 01-2 0v-2.38z"/><path pid="1" class="secondary" d="M21 14a1 1 0 010 2H3a1 1 0 010-2V4a1 1 0 110-2h18a1 1 0 010 2v10z"/><path pid="2" class="primary" d="M15.51 8.14a1 1 0 010 1.72l-5 3A1 1 0 019 12V6a1 1 0 011.51-.86l5 3z"/>'
  }
})
