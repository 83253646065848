/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paint': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M13 14a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6a1 1 0 011-1v-1.4a3 3 0 012.35-2.92l5.87-1.3A1 1 0 0020 7.4V7a1 1 0 00-1-1h-1V4h1a3 3 0 013 3v.4a3 3 0 01-2.35 2.92l-5.87 1.3a1 1 0 00-.78.98V14z"/><rect pid="1" width="17" height="6" x="2" y="2" class="primary" rx="2"/>'
  }
})
