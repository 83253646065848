var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"app-main-menu-container"},[_c('el-header',{staticClass:"app-main-menu",attrs:{"height":"50px"}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',{staticClass:"app-main-menu__title"},[_c('h6',[_vm._v(_vm._s(_vm.title))])]),(_vm.submenuItems[_vm.mainPath])?_c('el-col',{staticClass:"app-main-menu__submenu"},_vm._l((_vm.submenuItems[_vm.mainPath]),function(name){return _c('el-button',{key:name,attrs:{"size":"small","type":_vm.$route.name === name ? 'primary' : 'default',"disabled":name === 'settingsCloud' && !_vm.$store.getters['data/activeCloud'],"plain":""},on:{"click":function($event){return _vm.$router.push({ name })}}},[_vm._v(" "+_vm._s(_vm.$t(`pages.${name}`))+" ")])}),1):_vm._e(),_c('el-col',{staticClass:"app-main-menu__user"},[_c('el-popover',{attrs:{"placement":"top-end","trigger":"click","popper-class":"app-main-menu__search-popper","visible-arrow":false},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[_c('el-button',{class:[
              'app-main-menu__btn',
              'app-main-menu__apps-btn',
              { 'app-main-menu__btn--active': _vm.showSearch },
            ],attrs:{"slot":"reference","type":"text","size":"small"},slot:"reference"},[_c('icon',{attrs:{"name":"search","height":"20","width":"20"}})],1),_c('div',[_c('el-input',{ref:"search",staticClass:"app-main-menu__search-input",attrs:{"placeholder":_vm.$t('actions.search'),"size":"small"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(!_vm.searchQuery.length)?_c('div',{staticClass:"app-main-menu__search-content"},[_c('icon',{staticClass:"app-main-menu__search-icon",attrs:{"name":"box","width":"32","height":"32"}}),_c('span',[_vm._v(_vm._s(_vm.$t('enterText')))])],1):(_vm.searchResults.length)?_c('div',_vm._l((_vm.searchResults),function(result){return _c('div',{key:result.label},[_c('span',{staticClass:"app-main-menu__search-title"},[_vm._v(_vm._s(result.label))]),_c('el-divider'),_c('ul',{staticClass:"app-main-menu__search-list"},_vm._l((result.options),function(option,index){return _c('li',{key:option.label + index,class:[
                      'app-main-menu__search-item',
                      {
                        'app-main-menu__search-item--active': _vm.$route.path === option.path,
                      },
                    ],on:{"click":function($event){return _vm.handleSearchOptionClick(option.path)}}},[_vm._v(" "+_vm._s(option.label)+" "),(option.description)?_c('p',[_vm._v(_vm._s(option.description))]):_vm._e()])}),0)],1)}),0):_c('div',{staticClass:"app-main-menu__search-content"},[_c('icon',{staticClass:"app-main-menu__search-icon",attrs:{"name":"empty","width":"32","height":"32"}}),_c('p',[_vm._v(_vm._s(_vm.$t('noDataFound')))])],1)],1)],1),_c('el-popover',{attrs:{"placement":"top-end","trigger":"click","visible-arrow":false},on:{"after-leave":function($event){_vm.notifications.length && _vm.$store.commit('clearNotifications')}},model:{value:(_vm.showNotification),callback:function ($$v) {_vm.showNotification=$$v},expression:"showNotification"}},[_c('el-button',{class:[
              'app-main-menu__btn',
              'app-main-menu__apps-btn',
              { 'app-main-menu__btn--active': _vm.showNotification },
            ],attrs:{"slot":"reference","type":"text","size":"small"},slot:"reference"},[_c('el-badge',{attrs:{"is-dot":"","hidden":!_vm.notifications.length}},[_c('icon',{staticClass:"app-main-menu__btn app-main-menu__notification-btn",attrs:{"name":"notification","width":"20","height":"20"}})],1)],1),(_vm.notifications.length)?_c('div',_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.time,staticClass:"notification"},[_c('icon',{attrs:{"name":notification.icon || 'error-circle'}}),_c('div',{staticClass:"notification__content"},[_c('span',[_vm._v(_vm._s(notification.message))]),_c('span',{staticClass:"notification__content__time"},[_vm._v(_vm._s(_vm.$timeago.format(notification.time, _vm.$i18n.locale)))])])],1)}),0):_c('div',{staticClass:"app-main-menu__empty-notification"},[_c('icon',{attrs:{"name":"empty","width":"32","height":"32"}}),_c('span',[_vm._v(_vm._s(_vm.$t('noNotifications')))])],1)],1),_c('el-popover',{attrs:{"placement":"top-end","trigger":"click","visible-arrow":false},model:{value:(_vm.showApps),callback:function ($$v) {_vm.showApps=$$v},expression:"showApps"}},[_c('el-button',{class:[
              'app-main-menu__btn',
              'app-main-menu__apps-btn',
              { 'app-main-menu__btn--active': _vm.showApps },
            ],attrs:{"slot":"reference","type":"text","size":"small"},slot:"reference"},[_c('icon',{staticClass:"app-main-menu__btn app-main-menu__apps-btn",attrs:{"name":"apps","width":"20","height":"20"}})],1),_vm._l((_vm.hybridApps),function(app){return _c('a',{key:app.label,staticClass:"apps__item",attrs:{"href":app.link,"target":"_blank"}},[_c('span',{staticClass:"apps__item__icon",style:({ 'background-color': app.color })},[_c('icon',{attrs:{"name":app.icon,"color":"white","width":"20","height":"20"}})],1),_c('span',{staticClass:"apps__item__title"},[_vm._v(_vm._s(app.label))])])}),_c('el-divider'),_c('span',{staticClass:"apps__item__more"},[_vm._v(_vm._s(_vm.$t('moreAboutHSuite')))]),_c('a',{key:"Hybrid",staticClass:"apps__item",attrs:{"href":"https://hybrid.ai/suite","target":"_blank"}},[_c('span',{staticClass:"apps__item__icon apps__item__icon--hybrid"}),_c('span',{staticClass:"apps__item__title"},[_vm._v("Hybrid.ai")])])],2),_c('el-popover',{staticClass:"app-main-menu__user",attrs:{"placement":"top-end","trigger":"click","visible-arrow":false},model:{value:(_vm.showUserMenu),callback:function ($$v) {_vm.showUserMenu=$$v},expression:"showUserMenu"}},[(_vm.user && _vm.user.avatar)?_c('el-avatar',{attrs:{"slot":"reference","size":"small","src":_vm.user.avatar},slot:"reference"}):_c('el-avatar',{attrs:{"slot":"reference","size":"small"},slot:"reference"},[_vm._v(_vm._s(_vm.user.name.slice(0, 1)))]),_c('div',{staticClass:"user-menu__user-content"},[_c('b',[_vm._v(_vm._s(_vm.user.name))]),_c('span',[_vm._v(_vm._s(_vm.user.email))])]),_c('router-link',{class:[
              'app-main-menu__user-item',
              {
                'app-main-menu__user-item--active': _vm.$route.name === 'settingsProfile',
              },
            ],attrs:{"to":"/settings/profile"},nativeOn:{"click":function($event){_vm.showUserMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('pages.settingsProfile'))+" ")]),_c('router-link',{class:[
              'app-main-menu__user-item',
              {
                'app-main-menu__user-item--active': _vm.$route.name === 'settingsWallet',
              },
            ],attrs:{"to":"/settings/wallet"},nativeOn:{"click":function($event){_vm.showUserMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('pages.settingsWallet'))+" ")]),_c('el-divider'),_c('router-link',{staticClass:"app-main-menu__user-item",attrs:{"to":"/clouds"},nativeOn:{"click":function($event){_vm.showUserMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('changeCloud'))+" ")]),_c('div',{staticClass:"app-main-menu__user-item",on:{"click":_vm.handleLogout}},[_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }