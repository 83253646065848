/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M18 14.74a4 4 0 00-8 .26H3a2 2 0 01-2-2V5c0-1.1.9-2 2-2h14a2 2 0 012 2v8a2 2 0 01-1 1.74zM10 12a3 3 0 100-6 3 3 0 000 6z"/><path pid="1" class="primary" d="M7 9h14a2 2 0 012 2v8a2 2 0 01-2 2H7a2 2 0 01-2-2v-8c0-1.1.9-2 2-2zm7 9a3 3 0 100-6 3 3 0 000 6z"/>'
  }
})
