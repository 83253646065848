/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-date': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 4h14a2 2 0 012 2v13a2 2 0 01-2 2H5a2 2 0 01-2-2V6c0-1.1.9-2 2-2zm0 5v10h14V9H5z"/><path pid="1" class="secondary" d="M13 13h3v3h-3v-3zM7 2a1 1 0 011 1v3a1 1 0 11-2 0V3a1 1 0 011-1zm10 0a1 1 0 011 1v3a1 1 0 01-2 0V3a1 1 0 011-1z"/>'
  }
})
