/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M9.53 16.93a1 1 0 01-1.45-1.05l.47-2.76-2-1.95a1 1 0 01.55-1.7l2.77-.4 1.23-2.51a1 1 0 011.8 0l1.23 2.5 2.77.4a1 1 0 01.55 1.71l-2 1.95.47 2.76a1 1 0 01-1.45 1.05L12 15.63l-2.47 1.3z"/>'
  }
})
