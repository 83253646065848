/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M2.6 6.09l9-4a1 1 0 01.8 0l9 4a1 1 0 010 1.82l-9 4a1 1 0 01-.8 0l-9-4a1 1 0 010-1.82z"/><path pid="1" class="secondary" d="M3.91 10.5l7.68 3.41a1 1 0 00.82 0l7.68-3.41 1.32.59a1 1 0 010 1.82l-9 4a1 1 0 01-.82 0l-9-4a1 1 0 010-1.82l1.32-.59zm0 5l7.68 3.41a1 1 0 00.82 0l7.68-3.41 1.32.59a1 1 0 010 1.82l-9 4a1 1 0 01-.82 0l-9-4a1 1 0 010-1.82l1.32-.59z"/>'
  }
})
