/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots-horizontal': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" fill-rule="evenodd" d="M5 14a2 2 0 110-4 2 2 0 010 4zm7 0a2 2 0 110-4 2 2 0 010 4zm7 0a2 2 0 110-4 2 2 0 010 4z"/>'
  }
})
