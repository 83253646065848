<template>
  <div id="app">
    <template v-if="$route.meta.protected">
      <loader v-if="appLoading" />
      <el-container class="layout-content">
        <app-nav-menu v-if="$store.getters['data/activeCloud']" />
        <div class="layout-content__container">
          <app-main-menu />
          <main class="layout-content__main">
            <div v-loading="dataLoading" class="layout-content__main__container">
              <router-view />
            </div>
          </main>
        </div>
      </el-container>
    </template>

    <el-container v-else class="layout-content">
      <loader v-if="appLoading" />
      <main class="layout-content__main layout-content__main--auth">
        <router-view />
      </main>
    </el-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader'
import AppMainMenu from './components/AppMainMenu'
import AppNavMenu from './components/AppNavMenu'

export default {
  name: 'App',

  components: {
    Loader,
    AppMainMenu,
    AppNavMenu,
  },

  metaInfo() {
    return {
      titleTemplate: titleChunk =>
        titleChunk ? `${titleChunk} | ${this.$i18n.t('meta.title')}` : this.$i18n.t('meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('meta.description'),
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$i18n.t('meta.title'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$i18n.t('meta.description'),
        },
        { property: 'og:url', content: this.$route.fullPath },
        { property: 'twitter:title', content: this.$i18n.t('meta.title') },
        {
          property: 'twitter:description',
          content: this.$i18n.t('meta.description'),
        },
        { property: 'twitter:site', content: this.$route.fullPath },
      ],
    }
  },

  computed: {
    ...mapState({
      appLoading: state => state.loading,
      dataLoading: state => state.data.loading,
    }),
  },

  mounted() {
    const locale = localStorage.getItem('locale')
    if (locale && ['ru', 'en'].includes(locale)) {
      this.$root.$i18n.locale = locale
    }
  },
}
</script>

<i18n>
{
  "en": {
    "meta": {
      "title": "Hybrid Warehouse",
      "description": "Hybrid Warehouse is an ultra-flexible customer data platform (CDP) that helps you to agregate, enrich and manage data to solve complex marketing tasks."
    }
  },
  "ru": {
    "meta": {
      "title": "Hybrid Warehouse",
      "description": "Hybrid Warehouse - это гибкая платворма для управления данными клиента, позволяющая агрегировать, обогащать и управлять данными для решения сложных маркетинговых задач."
    }
  }
}
</i18n>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.layout-content {
  height: 100%;
  display: flex;
}
.layout-content__container {
  width: 100%;
  overflow: hidden;
}
.el-aside.layout-content__menu-container {
  height: 100%;
  overflow: visible;
}
.el-menu.layout-content__menu {
  height: 100%;
  background: $color-layout-light-01;
  border: 0;
}
.layout-content__main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-layout-light-02;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 8px 0 0 0;
}
.layout-content__main--auth {
  border-radius: 0;
}
.layout-header__menu-btn {
  color: white;
  cursor: pointer;
}
.layout-content__menu__icon {
  margin-right: $spacing-xs;
}
.layout-content__menu__item {
  cursor: pointer;
  font-weight: 500;
  color: $color-layout-dark-02;
  width: 100%;
}
.layout-content__menu {
  width: 200px;
}
.layout-content__menu.el-menu--collapse {
  width: 64px;
}
.layout-content__main__container {
  width: 100%;
  margin-bottom: 50px;
}
.layout-content__menu__item--active {
  color: $color-primary;
  opacity: 1;
}

.scale-enter-active,
.scale-leave-active {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.scale-enter,
.scale-leave-active {
  opacity: 0;
  transform: scale(0.97);
}
</style>
