/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="none"><path pid="0" class="secondary" d="M13 3v15.97l-1 1.12-8.6 1.82a1 1 0 01-1.3-1.36l9-18A.99.99 0 0112 2l1 1z"/><path pid="1" class="primary" d="M12 2c.36 0 .71.18.9.55l9 18a1 1 0 01-1.3 1.36L12 20.1V2z"/></g>'
  }
})
