/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'announcement': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5.04 14.3l-.1-.3H4a2 2 0 01-2-2v-2c0-1.1.9-2 2-2h1a1 1 0 011-1h6.34a3 3 0 002.12-.88l1.66-1.66A5 5 0 0119.66 3H21a1 1 0 011 1v14a1 1 0 01-1 1h-1.34a5 5 0 01-3.54-1.46l-1.66-1.66a3 3 0 00-2.12-.88H11v5a1 1 0 01-1 1H8a1 1 0 01-.95-.68l-2-5.98a1 1 0 010-.05z"/><rect pid="1" width="8" height="10" x="4" y="6" class="secondary" rx="1"/>'
  }
})
