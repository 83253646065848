/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-bag': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 8h14a1 1 0 011 .92l1 12A1 1 0 0120 22H4a1 1 0 01-1-1.08l1-12A1 1 0 015 8z"/><path pid="1" class="secondary" d="M9 10a1 1 0 01-2 0V7a5 5 0 1110 0v3a1 1 0 01-2 0V7a3 3 0 00-6 0v3z"/>'
  }
})
