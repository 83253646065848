import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: () => import(/* webpackChunkName: "auth" */ './views/Auth.vue'),
    },
    {
      path: '/reset-password/:token',
      name: 'resetPassword',
      component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
    },
    {
      path: '/confirm-email/:token',
      name: 'confirmEmail',
      component: () => import(/* webpackChunkName: "confirm-email" */ './views/ConfirmEmail.vue'),
    },
    {
      path: '/settings',
      redirect: { name: 'settingsProfile' },
      meta: { protected: true },
    },
    {
      path: '/settings/profile',
      name: 'settingsProfile',
      component: () =>
        import(/* webpackChunkName: "settings" */ './views/settings/SettingsProfile.vue'),
      meta: { protected: true, availableWithNoData: true },
    },
    {
      path: '/settings/wallet',
      name: 'settingsWallet',
      component: () =>
        import(/* webpackChunkName: "settings" */ './views/settings/SettingsWallet.vue'),
      meta: { protected: true, availableWithNoData: true },
    },
    {
      path: '/settings/cloud',
      name: 'settingsCloud',
      component: () =>
        import(/* webpackChunkName: "settings" */ './views/settings/SettingsCloud.vue'),
      meta: { protected: true },
    },
    {
      path: '/clouds',
      name: 'clouds',
      component: () => import(/* webpackChunkName: "clouds" */ './views/Clouds.vue'),
      meta: { protected: true },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      meta: { protected: true },
    },
    {
      path: '/data',
      redirect: { name: 'dataSources' },
      meta: { protected: true },
    },
    {
      path: '/data/sources',
      name: 'dataSources',
      component: () => import(/* webpackChunkName: "data" */ './views/data/DataSources.vue'),
      meta: { protected: true },
    },
    {
      path: '/data/attributes',
      name: 'dataAttributes',
      component: () => import(/* webpackChunkName: "data" */ './views/data/DataAttributes.vue'),
      meta: { protected: true },
    },
    {
      path: '/data/marketplace',
      name: 'dataMarketplace',
      component: () => import(/* webpackChunkName: "data" */ './views/data/DataMarketplace.vue'),
      meta: { protected: true },
    },
    {
      path: '/workspaces',
      name: 'workspaces',
      component: () =>
        import(/* webpackChunkName: "workspace" */ './views/workspaces/Workspaces.vue'),
      meta: { protected: true },
    },
    {
      path: '/workspace/:id',
      name: 'workspace',
      component: () =>
        import(/* webpackChunkName: "workspace" */ './views/workspaces/Workspace.vue'),
      meta: { protected: true },
    },
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.auth.isChecked) {
    store.commit('setLoading', true)
    await store.dispatch('user/checkUser')
  }

  if (store.state.auth.isAuthenticated) {
    !store.state.data.clouds && (await store.dispatch('data/getClouds'))
    if (!store.getters['data/activeCloud']) {
      if (
        to.path !== '/clouds' &&
        !to.matched.some(record => record.meta && record.meta.availableWithNoData)
      ) {
        return next('/clouds')
      }
    } else {
      store.dispatch('data/getData', to.path.split('/')[1])
      return next()
    }
  }

  if (to.matched.some(record => record.meta && !record.meta.protected)) {
    if (store.state.auth.isAuthenticated) {
      return next('/')
    }
  } else if (!store.state.auth.isAuthenticated) {
    return next({
      path: '/auth',
    })
  }

  next()
})

router.afterEach(() => {
  store.commit('setLoading', false)
})

export default router
