/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-group-alt': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="16" height="13" x="2" y="2" class="secondary" rx="2"/><path pid="1" class="primary" d="M6 16V8c0-1.1.9-2 2-2h12a2 2 0 012 2v13a1 1 0 01-1.7.7L16.58 18H8a2 2 0 01-2-2z"/>'
  }
})
