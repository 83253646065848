/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M14.76 19l.23.23A3 3 0 019 19h5.76zM3.29 4.7a1 1 0 011.42-1.4l16 16a1 1 0 01-1.42 1.4l-16-16z"/><path pid="1" class="secondary" d="M8.94 4.7c.35-.17.7-.3 1.08-.42L10 4a2 2 0 113.98.28A7 7 0 0119 11v3.76L8.94 4.7zM5.18 9.42L14.76 19H4a1 1 0 010-2 1 1 0 001-1v-5c0-.54.06-1.07.18-1.58z"/>'
  }
})
