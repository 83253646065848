/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bolt': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M14 10h2a1 1 0 01.81 1.58l-5 7A1 1 0 0110 18v-4H8a1 1 0 01-.81-1.58l5-7A1 1 0 0114 6v4z"/>'
  }
})
