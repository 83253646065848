/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wallet': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M2 5c0 1.1.9 2 2 2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm16 11a2 2 0 100-4 2 2 0 000 4z"/><path pid="1" class="secondary" d="M4 3h11a2 2 0 012 2v2H4a2 2 0 110-4z"/>'
  }
})
