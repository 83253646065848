<template>
  <!-- eslint-disable -->
  <div class="loader-background">
    <div class="loader-container">
      <div class="loader">
        <svg version="1.1" x="0px" y="0px" width="140px" height="140px" viewBox="0 0 206 206" enable-background="new 0 0 206 206" xml:space="preserve">
        <g class="big-cube">
          <polygon fill="#F2F2F3" points="16.402,53.002 16.402,53.002 103.002,103.001 189.604,53.002 103.002,3.002    "/>
          <polygon fill="#ACB3DA" points="103.002,103.001 103.002,203 103.002,203.001 189.604,153.001 189.604,53.002 103.002,103.001  "/>
          <polygon fill="#1F263C" points="16.4,53.002 16.402,153.001 103.002,203.001 103.002,203 103.002,103.001 103.002,103.001 16.402,53.002 16.402,53.002     "/>
        </g>
        <g class="little-cube">
          <polygon fill="#F2F2F3" points="146.301,128.001 146.301,128.001 103,103.001 59.7,128.001 103,153.001    "/>
          <polygon fill="#ACB3DA" points="103.002,103.001 103.002,53.002 103,53.002 59.7,78.002 59.7,128.001 103,103.001         "/>
          <polygon fill="#1F263C" points="146.303,128.001 146.301,78.002 103,53.002 103.002,53.002 103.002,103.001 103,103.001 146.301,128.001 146.301,128.001         "/>
        </g>
        <g class="secret-cube">
          <polygon fill="#F2F2F3" points="146.301,128.001 146.301,128.001 103,103.001 59.7,128.001 103,153.001    "/>
          <polygon fill="#ACB3DA" points="103.002,103.001 103.002,53.002 103,53.002 59.7,78.002 59.7,128.001 103,103.001         "/>
          <polygon fill="#1F263C" points="146.303,128.001 146.301,78.002 103,53.002 103.002,53.002 103.002,103.001 103,103.001 146.301,128.001 146.301,128.001         "/>
        </g>
        </svg>
      </div>
      <span class="loader-title"><b>hybrid</b> warehouse</span>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style lang="scss">
.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $color-primary;
}
.loader-container {
  position: absolute;
  top: calc(50% - 200px / 2);
  left: calc(50% - 300px / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader-title {
  margin-top: 16px;
  font-size: 36px;
  color: white;
  b {
    font-weight: 600;
  }
}

@keyframes bigcubemotion {
  0% {
    transform: rotate(0turn);
  }
  5% {
    transform: rotate(0turn);
  }
  40% {
    transform: rotate(0.5turn);
  }
  100% {
    transform: rotate(0.5turn);
  }
}

.big-cube {
  transform-origin: 50% 50%;
  animation-timing-function: ease-in-out;
  animation-name: bigcubemotion;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes littlecubemotion {
  0% {
    transform: rotate(0turn);
  }
  5% {
    transform: rotate(0turn);
  }
  40% {
    transform: rotate(0.5turn);
  }
  66% {
    transform: rotate(0.5turn) scale(2.07);
  }
  97% {
    transform: rotate(0.5turn) scale(2);
  }
  100% {
    transform: rotate(0.5turn) scale(2);
  }
}

.little-cube {
  transform-origin: 50% 50%;
  animation-timing-function: ease-in-out;
  animation-name: littlecubemotion;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes secretcubemotion {
  0% {
    transform: scale(0);
  }
  5% {
    transform: scale(0);
  }
  40% {
    transform: scale(0);
  }
  67% {
    transform: scale(1.13);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.secret-cube {
  transform-origin: 50% 50%;
  animation-timing-function: cubic;
  animation-name: secretcubemotion;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
</style>
