/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows-split': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M14 16l-3.45-3.45a12 12 0 012.96-4.9l2.37-2.36-1.59-1.58A1 1 0 0115 2h6a1 1 0 011 1v6a1 1 0 01-1.7.7l-1.6-1.58-2.36 2.37A8 8 0 0014 16z"/><path pid="1" class="primary" d="M8.12 5.3l2.37 2.36A12 12 0 0114 16.14V21a1 1 0 01-1 1h-2a1 1 0 01-1-1v-4.86a8 8 0 00-2.34-5.65L5.29 8.12 3.71 9.71A1 1 0 012 9V3a1 1 0 011-1h6a1 1 0 01.7 1.7L8.13 5.3z"/>'
  }
})
