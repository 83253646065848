import Vue from 'vue'
import axios from 'axios'

import store from '../store'

const _axios = axios.create({
  withCredentials: true,
})

_axios.interceptors.response.use(
  response => (response ? response.data : {}),
  err => {
    if (!err || !err.response) {
      return Promise.reject({})
    }
    if (
      err.response.status === 401 &&
      err.response.config &&
      !err.response.config.__isRetryRequest &&
      store.state.auth.isChecked
    ) {
      store.dispatch('auth/logout')
    } else if (
      err.response.status === 500 &&
      err.response.config &&
      !err.response.config.__isRetryRequest
    ) {
      store.dispatch('showSystemError')
    }

    return Promise.reject(err.response)
  }
)

const Plugin = {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $axios: {
        get() {
          return _axios
        },
      },
    })
  },
}

Vue.use(Plugin)

export default _axios
