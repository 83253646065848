/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><rect pid="1" width="7" height="7" x="8.5" y="8.5" class="secondary" rx="1"/>'
  }
})
