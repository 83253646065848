/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'analytics': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5c0-1.1.9-2 2-2zm11 4a1 1 0 00-1 1v8a1 1 0 002 0V8a1 1 0 00-1-1z"/><path pid="1" class="secondary" d="M8 11a1 1 0 011 1v4a1 1 0 01-2 0v-4a1 1 0 011-1zm4-2a1 1 0 011 1v6a1 1 0 01-2 0v-6a1 1 0 011-1z"/>'
  }
})
