/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trending-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M3.7 3.3a1 1 0 10-1.4 1.4l6 6a1 1 0 001.4 0L13 7.42l4.3 4.3a1 1 0 001.4-1.42l-5-5a1 1 0 00-1.4 0L9 8.6 3.7 3.3z"/><path pid="1" class="primary" d="M16.59 17L13 13.41l-3.3 3.3a1 1 0 01-1.4 0l-6-6a1 1 0 011.4-1.42L9 14.6l3.3-3.3a1 1 0 011.4 0l4.3 4.3 2.3-2.3a1 1 0 011.7.7v6a1 1 0 01-1 1h-6a1 1 0 01-.7-1.7l2.29-2.3z"/>'
  }
})
