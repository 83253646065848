/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" fill-rule="evenodd" d="M15.78 14.36a1 1 0 01-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 11-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 011.42-1.42l2.83 2.83 2.82-2.83a1 1 0 011.42 1.42l-2.83 2.83 2.83 2.82z"/>'
  }
})
