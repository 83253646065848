/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-square': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="18" height="18" x="3" y="3" class="primary" rx="2"/><path pid="1" class="secondary" d="M13 11h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4H7a1 1 0 010-2h4V7a1 1 0 012 0v4z"/>'
  }
})
