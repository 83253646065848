/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M12 2a10 10 0 110 20 10 10 0 010-20z"/><path pid="1" class="secondary" d="M11 12a1 1 0 010-2h2a1 1 0 01.96 1.27L12.33 17H13a1 1 0 010 2h-2a1 1 0 01-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"/>'
  }
})
