/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'empty': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M20.77 5a2 2 0 01-.02.22l-1.55 14a2 2 0 01-2 1.78H6.79a2 2 0 01-1.99-1.78l-1.55-14L3.23 5h17.54z"/><path pid="1" class="primary" d="M10.59 14l-2.3-2.3a1 1 0 011.42-1.4L12 12.58l2.3-2.3a1 1 0 011.4 1.42L13.42 14l2.3 2.3a1 1 0 01-1.42 1.4L12 15.42l-2.3 2.3a1 1 0 11-1.4-1.42l2.28-2.3zM4 3h16a2 2 0 012 2v1a1 1 0 01-1 1H3a1 1 0 01-1-1V5c0-1.1.9-2 2-2z"/>'
  }
})
