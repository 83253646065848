/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device-tablet': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M6 2h12a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4c0-1.1.9-2 2-2z"/><path pid="1" class="secondary" d="M12 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>'
  }
})
