/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mood-neutral': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M8 15h8a1 1 0 010 2H8a1 1 0 010-2zm.5-4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm7 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"/>'
  }
})
