import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../plugins/i18n'

import auth from './modules/auth'
import user from './modules/user'
import data from './modules/data'

Vue.use(Vuex)

const initialState = {
  auth: { ...auth.state },
  user: { ...user.state },
  data: { ...data.state },
}

const store = new Vuex.Store({
  namespaced: true,

  modules: {
    auth,
    user,
    data,
  },

  state: {
    loading: false,
    notifications: [],
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    addNotification(state, notification) {
      notification.time = Date.now()
      state.notifications.push(notification)
    },
    clearNotifications(state) {
      state.notifications = []
    },
    reset(state) {
      Object.keys(initialState).forEach(key => {
        Object.assign(state[key], initialState[key])
      })
      state.notifications = []
      state.auth.isChecked = true
    },
  },

  actions: {
    notify(context, options) {
      Vue.prototype.$notify(options)
    },
    showSystemError() {
      Vue.prototype.$notify.error({
        title: i18n.t('messages.systemError.title'),
        message: i18n.t('messages.systemError.message'),
      })
    },
  },
})

export default store
