/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'package': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M15 3.333V4.67h3.008L21 6l-7 6-2 10-9-4V6l9-4z"/><path pid="1" class="primary" d="M21 6v12l-9 4V10l9-4zM9 8.67v3l-3-1.34v-3l9-4 3 1.34-9 4z"/>'
  }
})
