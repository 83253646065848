/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-remove': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M22 10H2V6c0-1.1.9-2 2-2h7l2 2h7a2 2 0 012 2v2z"/><rect pid="1" width="20" height="12" x="2" y="8" class="secondary" rx="2"/><path pid="2" class="primary" d="M9 13h6a1 1 0 010 2H9a1 1 0 010-2z"/>'
  }
})
