/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M12.88 8.88a3 3 0 114.24 4.24l-4.41 4.42a1 1 0 01-1.42 0l-4.41-4.42a3 3 0 114.24-4.24l.88.88.88-.88z"/>'
  }
})
