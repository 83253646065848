/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-decending': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M6 11V4a1 1 0 112 0v7h3a1 1 0 01.7 1.7l-4 4a1 1 0 01-1.4 0l-4-4A1 1 0 013 11h3z"/><path pid="1" class="primary" d="M21 21H8a1 1 0 010-2h13a1 1 0 010 2zm0-4h-9a1 1 0 010-2h9a1 1 0 010 2zm0-4h-5a1 1 0 010-2h5a1 1 0 010 2z"/>'
  }
})
