import Vue from 'vue'
import { format, register, render, cancel } from 'timeago.js'
import { ru, en_short } from 'timeago.js/lib/lang'

register('en', en_short)
register('ru', ru)

const timeago = { format, render, cancel }

const Plugin = {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $timeago: {
        get() {
          return timeago
        },
      },
    })
  },
}

Vue.use(Plugin)

export default timeago
