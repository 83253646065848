/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M10 14.92V16a1 1 0 001 1h2a1 1 0 001-1v-1.08c2.83-.24 5.53-.96 8-2.1V20a2 2 0 01-2 2H4a2 2 0 01-2-2v-7.18a23.85 23.85 0 008 2.1z"/><path pid="1" class="secondary" d="M14 12.92V12a1 1 0 00-1-1h-2a1 1 0 00-1 1v.92a23.85 23.85 0 01-8-2.1V8c0-1.1.9-2 2-2h3V5a3 3 0 013-3h4a3 3 0 013 3v1h3a2 2 0 012 2v2.82a23.85 23.85 0 01-8 2.1zM15 6V5a1 1 0 00-1-1h-4a1 1 0 00-1 1v1h6z"/>'
  }
})
