<template>
  <el-aside v-if="$route.name !== 'clouds'" width="auto" class="layout-content__menu-container">
    <el-menu
      :default-active="`/${activeItem}`"
      :collapse="collapsed"
      class="layout-content__menu"
      router
    >
      <el-menu-item class="layout-content__menu__item layout-content__logo">
        <icon width="28" height="28" name="brick" />
        <span><b>hybrid</b> warehouse</span>
      </el-menu-item>
      <el-menu-item
        index="/dashboard"
        :class="[
          'layout-content__menu__item',
          { 'layout-content__menu__item--active': activeItem === 'dashboard' },
        ]"
      >
        <icon width="20" height="20" name="dashboard" />
        <span>{{ $t('pages.dashboard') }}</span>
      </el-menu-item>
      <el-menu-item
        index="/data"
        :class="[
          'layout-content__menu__item',
          { 'layout-content__menu__item--active': activeItem === 'data' },
        ]"
      >
        <icon width="20" height="20" name="server" />
        <span>{{ $t('pages.data') }}</span>
      </el-menu-item>
      <el-menu-item
        index="/workspaces"
        :class="[
          'layout-content__menu__item',
          { 'layout-content__menu__item--active': activeItem === 'workspaces' },
        ]"
      >
        <icon width="20" height="20" name="inbox-full" />
        <span>{{ $t('pages.workspaces') }}</span>
      </el-menu-item>
      <div v-if="!collapsed && activeWorkspacesList" class="layout-content__menu__workspace">
        <el-menu-item-group :title="$t('pinnedWorkspaces')">
          <el-menu-item
            v-for="workspace in pinnedWorkspaces"
            :key="workspace.id"
            :index="`/workspace/${workspace.id}`"
            :class="[
              'submenu__workspaces__item',
              {
                'layout-content__menu__item--active':
                  activeItem === 'workspace' && $route.params.id === workspace.id,
              },
            ]"
          >
            {{ workspace.name }}
          </el-menu-item>
        </el-menu-item-group>
      </div>
      <div class="layout-content__placeholder" />
      <el-menu-item
        index="/settings"
        :class="[
          'layout-content__menu__item',
          { 'layout-content__menu__item--active': activeItem === 'settings' },
        ]"
      >
        <icon width="20" height="20" name="cog" />
        <span>{{ $t('pages.settings') }}</span>
      </el-menu-item>
      <el-menu-item class="layout-content__menu__item" @click="toggleCollapsed">
        <icon width="20" height="20" :name="collapsed ? 'arrow-right' : 'arrow-left'" />
        <span>{{ $t('collapse') }}</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppNavMenu',

  data() {
    return {
      collapsed: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeWorkspacesList']),
    pinnedWorkspaces() {
      return this.activeWorkspacesList.filter(workspace => workspace.pinned)
    },
    activeItem() {
      return this.$route.path.split('/')[1]
    },
  },

  mounted() {
    this.collapsed = localStorage.getItem('menuCollapsed') === 'true'
  },

  methods: {
    toggleCollapsed() {
      const collapsed = !this.collapsed
      this.collapsed = collapsed
      localStorage.setItem('menuCollapsed', collapsed)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "collapse": "Collapse",
    "pinnedWorkspaces": "Pinned workspaces"
  },
  "ru": {
    "collapse": "Свернуть",
    "pinnedWorkspaces": "Избранное"
  }
}
</i18n>

<style lang="scss">
.app-main-menu__title {
  display: flex;
  align-items: center;
  svg {
    margin-right: $spacing-s;
  }
  a {
    color: $color-layout-dark-01;
    font-weight: 500;
  }
}
.layout-content__menu {
  display: flex;
  flex-direction: column;
}
.layout-content__menu__workspace {
  padding: 0 $spacing-xl;
  margin-top: $spacing-s;
}
.layout-content__logo {
  margin-bottom: $spacing-m;
  padding-left: 16px !important;
}
.layout-content__menu__item {
  b {
    font-weight: 600;
  }
  span,
  b {
    vertical-align: baseline;
  }
}
.submenu__workspaces__item {
  height: 36px;
  line-height: 36px;
  padding-left: 12px !important;
}
.layout-content__placeholder {
  flex: 1;
}
</style>
