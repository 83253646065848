/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-thick-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="10" class="primary"/><path pid="1" class="secondary" d="M10 12V7a1 1 0 011-1h2a1 1 0 011 1v5h2a1 1 0 01.7 1.7l-4 4a1 1 0 01-1.4 0l-4-4A1 1 0 018 12h2z"/>'
  }
})
