/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collection': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" width="20" height="12" x="2" y="10" class="primary" rx="2"/><path pid="1" class="secondary" d="M20 8H4c0-1.1.9-2 2-2h12a2 2 0 012 2zm-2-4H6c0-1.1.9-2 2-2h8a2 2 0 012 2z"/>'
  }
})
