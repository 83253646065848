/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-open': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M12 21a2 2 0 01-1.41-.59l-.83-.82A2 2 0 008.34 19H4a1 1 0 01-1-1V4a1 1 0 011-1h4a5 5 0 014 2v16z"/><path pid="1" class="primary" d="M12 21V5a5 5 0 014-2h4a1 1 0 011 1v14a1 1 0 01-1 1h-4.34a2 2 0 00-1.42.59l-.83.82A2 2 0 0112 21z"/>'
  }
})
