/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fast-rewind': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M22 5a1 1 0 00-1.5-.86l-12 7a1 1 0 000 1.72l12 7A1 1 0 0022 19V5z"/><path pid="1" class="primary" d="M15 5a1 1 0 00-1.5-.86l-12 7a1 1 0 000 1.72l12 7A1 1 0 0015 19V5z"/>'
  }
})
