/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'desktop': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="primary" d="M4 2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V4c0-1.1.9-2 2-2zm0 2v10h16V4H4zm4 16a1 1 0 011-1h6a1 1 0 011 1v.59l.7.7A1 1 0 0116 23H8a1 1 0 01-.7-1.7l.7-.71V20z"/><path pid="1" class="secondary" d="M2 14h20v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2z"/>'
  }
})
