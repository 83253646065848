/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cheveron-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" fill-rule="evenodd" d="M8.7 13.7a1 1 0 11-1.4-1.4l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L12 10.42l-3.3 3.3z"/>'
  }
})
