/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bug': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="secondary" d="M3 9a1 1 0 112 0c0 1.1.9 2 2 2h1v2H7a4 4 0 01-4-4zm18 0a4 4 0 01-4 4h-1v-2h1a2 2 0 002-2 1 1 0 012 0zM4 21a4 4 0 014-4h1v2H8a2 2 0 00-2 2 1 1 0 01-2 0zm-1-7h4a1 1 0 010 2H3a1 1 0 010-2zm14 0h4a1 1 0 010 2h-4a1 1 0 010-2zm3 7a1 1 0 01-2 0 2 2 0 00-2-2h-1v-2h1a4 4 0 014 4zM8.27 3.67a5 5 0 017.46 0A2 2 0 0114.23 7H9.77a2 2 0 01-1.49-3.33z"/><path pid="1" class="primary" d="M13 19.58V13a1 1 0 00-2 0v6.58a1 1 0 01-1.4.92A6 6 0 016 15v-3a4 4 0 014-4h4a4 4 0 014 4v3a6 6 0 01-3.6 5.5 1 1 0 01-1.4-.92z"/>'
  }
})
